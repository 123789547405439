import { Grid, Snackbar } from '@material-ui/core'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

import { useMessageStore } from '~/store/message-store'

export const Message = () => {
  const { message, timeDuration, type, open } = useMessageStore()
  const close = useMessageStore((state) => state.close)

  return (
    <>
      {message && (
        <Grid item xs={12} justifyContent="center">
          <Snackbar open={open} autoHideDuration={timeDuration} onClose={close}>
            <Alert onClose={close} severity={type}>
              {message}
            </Alert>
          </Snackbar>
        </Grid>
      )}
    </>
  )
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}
