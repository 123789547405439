import React from 'react'
import { useHistory } from 'react-router-dom'

import { Button, Grid, Paper, Typography } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'

import { useUserStore } from '~/store/user/user-store'

import UserDataInfo from './components/user-data-info'
import UserFarmerInfo from './components/user-farmer-info'
import { useStyles } from './style'

const InformationUser: React.FC = () => {
  const classes = useStyles()
  const { userDataById } = useUserStore()

  const history = useHistory()

  return (
    <Grid item xs={12} className={classes.gridMain}>
      <Paper elevation={0} className={classes.paper}>
        <Grid container item xs={12}>
          <Grid item xs={1}>
            <Button
              variant="contained"
              color="primary"
              className={classes.iconBack}
              onClick={() => {
                history.goBack()
              }}
            >
              <ArrowBack />
            </Button>
          </Grid>
          <Grid item xs>
            <Typography variant="h4" color="primary">
              {userDataById.nome}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item xs={12} style={{ paddingTop: '50px' }} spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <UserDataInfo />
              </Grid>
              <Grid item xs={12}>
                <UserFarmerInfo />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default InformationUser
