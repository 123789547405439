import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import {
  Grid,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Checkbox,
  ListItemText,
  Button,
  List,
  ListItem,
  Chip,
} from '@material-ui/core'
import { GridLinkOperator } from '@material-ui/data-grid'
import { ArrowBack } from '@material-ui/icons'
import { AxiosError } from 'axios'

import { useAlert } from '~/hooks/message'
import { api } from '~/services/api'
import { useStoreMissions } from '~/store/missions-store'
import { CpfCnpjMask, FormatPhone } from '~/utils'

import AddInteraction from './components/add-interaction-auxiliary-source'
import GridAuditSummary from './components/grid-audit-summary'
import GridInteractionAuxiliary from './components/grid-interaction-auxiliary'
import GridInteractionFarmer from './components/grid-interaction-farmer'
import strings from './strings'
import { useStyles } from './styles'

const ITEM_HEIGHT = 58
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      marginTop: 55,
    },
  },
}

type PropsFarmer = {
  id: number
  nome: string
  documento: string
  celular: string
  email: string
  status: string
}

type Params = {
  cliente_id: string
}

const Audit = () => {
  const classes = useStyles()

  const [missionSelecteds, setMissionSelecteds] = useState<string[]>([])
  const [openModal, setOpenModal] = useState<boolean>(false)
  const history = useHistory()
  const { cliente_id } = useParams<Params>()
  const alert = useAlert()
  const [farmer, setFarmer] = useState<PropsFarmer>()
  const { missions } = useStoreMissions()
  const [reloadAuxiliary, setRealoadAuxiliary] = useState<number>(0)

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setMissionSelecteds(event.target.value as string[])
  }

  let filtros
  if (missionSelecteds)
    filtros = {
      filterModel: {
        linkOperator: GridLinkOperator.Or,
        items: missionSelecteds.map((value, index) => {
          return {
            id: index,
            columnField: 'safra',
            operatorValue: 'contains',
            value,
          }
        }),
      },
    }

  useEffect(() => {
    api
      .get(`/farmer/${cliente_id}`)
      .then((res: any) => setFarmer(res.data))
      .catch((error: AxiosError) => {
        alert.show({ message: error.response?.data.message, type: 'error' })
      })
  }, [])

  return (
    <Grid item xs={12} className={classes.gridMain}>
      <Paper elevation={0} className={classes.paper}>
        <Grid container item xs={12}>
          <Grid item xs={1}>
            <Button
              variant="contained"
              color="primary"
              className={classes.iconBack}
              onClick={() => history.goBack()}
            >
              <ArrowBack />
            </Button>
          </Grid>
          <Grid item xs>
            <Typography variant="h4" color="primary">
              {strings.detalhes}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} className={classes.headerContainer}>
          <Grid item sm={6}>
            <Typography variant="h4" color="primary" className={classes.titleName}>
              {farmer?.nome?.toUpperCase()}
            </Typography>
          </Grid>
          <Grid item sm={6} className={classes.gridSelectMission}>
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel id="mutiple-checkbox-label">{strings.filterMission}</InputLabel>
              <Select
                labelId="mutiple-checkbox-label"
                id="mission-select"
                multiple
                value={missionSelecteds}
                onChange={handleChange}
                input={<Input />}
                renderValue={(selected) => (selected as string[]).join(', ')}
                MenuProps={MenuProps}
              >
                {missions
                  .sort((a, b) => b.id - a.id)
                  .map(({ id, titulo }: any) => (
                    <MenuItem key={id} value={titulo}>
                      <Checkbox checked={missionSelecteds.indexOf(titulo) > -1} />

                      <ListItemText primary={titulo} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item xs={12} className={classes.bodyInformation}>
          <Paper variant="outlined" className={classes.paperInfoFarmer}>
            <Grid item xs={12} sm={12} container spacing={2}>
              <Grid item xs container justifyContent="flex-start" alignContent="center">
                <Grid item sm={3}>
                  <Typography variant="h5" className={classes.typography}>
                    {strings.farmerDescription.title}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item sm={3}>
                  <List>
                    <ListItem>
                      <ListItemText
                        classes={{
                          primary: classes.primary,
                          secondary: classes.secundary,
                        }}
                        primary={strings.farmerDescription.cpf}
                        secondary={farmer?.documento ? CpfCnpjMask(farmer?.documento) : ''}
                      />
                    </ListItem>
                  </List>
                </Grid>

                <Grid item sm={3}>
                  <List>
                    <ListItem>
                      <ListItemText
                        classes={{
                          primary: classes.primary,
                          secondary: classes.secundary,
                        }}
                        primary={strings.farmerDescription.mail}
                        secondary={farmer?.email}
                      />
                    </ListItem>
                  </List>
                </Grid>

                <Grid item sm={3}>
                  <List>
                    <ListItem>
                      <ListItemText
                        classes={{
                          primary: classes.primary,
                          secondary: classes.secundary,
                        }}
                        primary={strings.farmerDescription.phone}
                        secondary={farmer?.celular ? FormatPhone(farmer.celular) : ''}
                      />
                    </ListItem>
                  </List>
                </Grid>

                <Grid item sm={3}>
                  <List>
                    <ListItem>
                      <Grid container direction="column" style={{ maxWidth: '120px' }}>
                        <ListItemText
                          classes={{
                            primary: classes.primary,
                            secondary: classes.secundary,
                          }}
                          primary={strings.farmerDescription.status}
                        />
                        <Chip
                          label={farmer?.status === '1' || '0' ? 'Ativo' : 'Inativo'}
                          style={
                            '1' || '0'
                              ? { backgroundColor: '#24d2b5', color: '#fff' }
                              : { backgroundColor: '#ff5c6c', color: '#fff' }
                          }
                        />
                      </Grid>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid container item xs={12} className={classes.bodyInformation}>
          <GridAuditSummary filtros={filtros} />
        </Grid>
        <Grid container item xs={12} className={classes.bodyInformation}>
          <GridInteractionFarmer filtros={filtros} />
        </Grid>
        <Grid container item xs={12} className={classes.bodyInformation}>
          <GridInteractionAuxiliary
            setOpenModal={setOpenModal}
            reloadAuxiliary={reloadAuxiliary}
            filtros={filtros}
          />
        </Grid>
        <Grid container item xs={12} className={classes.bodyInformation}>
          <AddInteraction
            open={openModal}
            onClose={() => setOpenModal(false)}
            cliente_id={cliente_id}
            setRealoadAuxiliary={setRealoadAuxiliary}
          />
        </Grid>
      </Paper>
    </Grid>
  )
}

export default Audit
