/* eslint-disable react-hooks/rules-of-hooks */
import Swal from 'sweetalert2'
import create from 'zustand'

import { api } from '~/services/api'
import { ClientProps, ClientList } from '~/types/client-types'

type ClientState = {
  isLoading: boolean
  messageError: any
  client?: ClientProps
  clientList: ClientList[]
  farmerLinkedAgpros: any[]
}

type UserActions = {
  update: (params: ClientProps, clientId: number) => Promise<void>
  getClientById: (clientId: number | string) => Promise<any>
  load: () => Promise<any>
  getFarmerLinkedAgpros: (clientId: number | string) => Promise<void>
}

type State = ClientState & UserActions

const initialState: ClientState = {
  isLoading: false,
  messageError: [],
  clientList: [],
  farmerLinkedAgpros: [],
}

export const useClientStore = create<State>((set, get) => ({
  ...initialState,
  load: async () => {
    set({ isLoading: true, messageError: '', clientList: [] })
    try {
      const { data: clientList } = await api.get('/client/list')

      set({ clientList })
    } catch (error: any) {
      set({ messageError: error?.response?.data?.message })
    } finally {
      set({ isLoading: false })
    }
  },
  update: async (params, clientId) => {
    const { getClientById } = get()
    set({ isLoading: true, messageError: '' })
    await api
      .put(`/client/${clientId}`, params)
      .then((resp) => {
        set({ isLoading: false })
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: resp.data.message,
          confirmButtonColor: '#2b78c0',
          confirmButtonText: 'OK',
          customClass: {
            container: 'alert-container',
          },
        })
        getClientById(clientId)
      })
      .catch((err) => {
        set({
          isLoading: false,
          messageError: err.response?.data?.errors,
        })
      })
  },
  getFarmerLinkedAgpros: async (clientId) => {
    set({
      isLoading: true,
    })
    try {
      const { data } = await api.get(`client/agpros/${clientId}`)
      set({
        farmerLinkedAgpros: data,
        isLoading: false,
      })
    } catch (error: any) {
      set({
        isLoading: false,
        messageError: error?.response?.data?.message,
      })
    }
  },
  getClientById: async (clientId) => {
    set({ isLoading: true })
    try {
      const { data: client } = await api.get<ClientProps>(`/client/${clientId}`)
      set({ client })
      return client
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Opss, Algo erro acontenceu!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }
    return {}
  },
}))
