export enum Title {
  LOGIN = 'Admin | Fazer login',
  NOT_FOUND = 'Nao Encontrado',
  HOME = 'Admin | Home',
  AUDIT = 'Admin | Auditoria',
  USERS = 'Admin | Users',
  ACESSOCTV = 'Admin | CTV',
  GROWCROPS = 'Admin | Cultivar',
  CLIENTS = 'Admin | Agricultores',
  ENLARGEREGION = 'Admin | regioes',
  VALIDATION = 'Admin | Validation',
  CONSULTANCY = 'Admin | Consultoria',
  ASSOCIATION = 'Admin | Associação',
}
