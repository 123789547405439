import { mask as toMask, unMask } from 'remask'

const maskType = {
  document: ['999.999.999-99', '99.999.999/9999-99'],
  cpf: ['999.999.999-99'],
  cnpj: ['99.999.999/9999-99'],
  phone: ['(99) 9999-9999', '(99) 9 9999-9999'],
  zipCode: ['99999-999'],
}

export const maskInterceptor = (mask: keyof typeof maskType) => {
  return (value: string) => {
    if (!value) return value

    const originalValue = unMask(value)
    const maskedValue = toMask(originalValue, maskType[mask])
    return maskedValue
  }
}
