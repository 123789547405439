import React from 'react'

import {
  Home,
  People,
  EmojiPeople,
  VerifiedUser,
  Security,
  Spa,
  Face,
  ZoomOutMap,
  Beenhere,
  BusinessCenter,
} from '@material-ui/icons'

import { AppRoutePath } from '~/constants/paths'

type Item = {
  readonly title: string
  readonly icon: React.ReactNode
  readonly route: AppRoutePath
  readonly focused: boolean
}

export default [
  {
    title: 'Início',
    icon: <Home />,
    route: AppRoutePath.HOME,
    focused: false,
  },
  {
    title: 'Validação',
    icon: <Beenhere />,
    route: AppRoutePath.VALIDATION,
    focused: false,
  },
  {
    title: 'Auditoria',
    icon: <VerifiedUser />,
    route: AppRoutePath.AUDIT,
    focused: false,
  },
  {
    title: 'Agricultores',
    icon: <Face />,
    route: AppRoutePath.CLIENTS,
    focused: false,
  },
  {
    title: 'Usuários',
    icon: <People />,
    route: AppRoutePath.USERS,
    focused: false,
  },
  {
    title: 'Cultivares',
    icon: <Spa />,
    route: AppRoutePath.GROWCROPS,
    focused: false,
  },
  {
    title: 'Acesso CTVs',
    icon: <Security />,
    route: AppRoutePath.CTV,
    focused: false,
  },
  {
    title: 'Consultoria',
    icon: <EmojiPeople />,
    route: AppRoutePath.CONSULTANCY,
    focused: false,
  },
  {
    title: 'Regiões',
    icon: <ZoomOutMap />,
    route: AppRoutePath.ENLARGEREGION,
    focused: false,
  },

  {
    title: 'Associação',
    icon: <BusinessCenter />,
    route: AppRoutePath.ASSOCIATION,
    focused: false,
  },
] as Item[]
