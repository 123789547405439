import React, { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

import {
  Chip,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core'
import { Edit } from '@material-ui/icons'

import EditUser from '~/components/edit-user'
import { PerfilAdmin, TypeAgpro, TypeCargos } from '~/enums/enum'
import { useUserStore } from '~/store/user/user-store'
import { FormsHandleEdit } from '~/types/user-types/user-types'
import { maskInterceptor } from '~/utils/mask-interceptor'

import strings from './strings'
import { useStyles } from './style'
import 'react-loading-skeleton/dist/skeleton.css'

type RouteParamsProps = {
  userId: string
}

const UserDataInfo: React.FC = () => {
  const classes = useStyles()
  const { userId } = useParams<RouteParamsProps>()

  const { editDataUserId, userDataById, getUser, listRelationShipClient } = useUserStore()

  const editUserRef = useRef<FormsHandleEdit>(null)

  const OpenModalEditUser = (user: any) => {
    editUserRef.current?.openModalEditForms(user)
  }

  const getListUserById = async (userId: number) => {
    const data = await editDataUserId(userId)
    OpenModalEditUser(data)
  }

  useEffect(() => {
    getUser(userId)
    listRelationShipClient(userId)
  }, [getUser, userId])

  return (
    <Grid item xs={12}>
      <Paper variant="outlined" className={classes.paper}>
        <Grid container item xs={12} justifyContent="space-between" style={{ padding: '0px 10px' }}>
          <Typography variant="h4"> {strings.userInfoTitle} </Typography>
          <IconButton
            component="span"
            color="primary"
            onClick={() => getListUserById(Number(userId))}
            id="button-editar-user"
          >
            <Edit />
          </IconButton>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid container item xs={12} justifyContent="space-between" alignItems="center">
            <Grid item sm={3}>
              <List>
                <ListItem>
                  <ListItemText
                    classes={{
                      primary: classes.primary,
                      secondary: classes.secundary,
                    }}
                    primary={strings.document}
                    secondary={maskInterceptor('cpf')(userDataById?.cpf)}
                  />
                </ListItem>
              </List>
            </Grid>

            <Grid item sm={5}>
              <List>
                <ListItem>
                  <ListItemText
                    classes={{
                      primary: classes.primary,
                      secondary: classes.secundary,
                    }}
                    primary={strings.email}
                    secondary={userDataById?.email}
                  />
                </ListItem>
              </List>
            </Grid>

            <Grid item sm={2}>
              <List>
                <ListItem>
                  <ListItemText
                    classes={{
                      primary: classes.primary,
                      secondary: classes.secundary,
                    }}
                    primary={strings.phone}
                    secondary={maskInterceptor('phone')(userDataById?.celular)}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item sm={2}>
              <List>
                <ListItem>
                  <Grid container direction="column" style={{ maxWidth: '120px' }}>
                    <ListItemText
                      classes={{
                        primary: classes.primary,
                        secondary: classes.secundary,
                      }}
                      primary={strings.situation}
                    />
                    <Chip
                      label={userDataById?.status === '1' ? 'Ativo' : 'Inativo'}
                      style={
                        userDataById?.status === '1'
                          ? { backgroundColor: '#24d2b5', color: '#fff' }
                          : { backgroundColor: '#ff5c6c', color: '#fff' }
                      }
                    />
                  </Grid>
                </ListItem>
              </List>
            </Grid>
            <Divider variant="fullWidth" component="article" style={{ width: '100%' }} />
          </Grid>

          <Grid container item xs={12} sm={12} justifyContent="space-between" alignItems="center">
            <Grid item sm={2}>
              <List>
                <ListItem>
                  <ListItemText
                    classes={{
                      primary: classes.primary,
                      secondary: classes.secundary,
                    }}
                    primary={strings.cargo}
                    secondary={TypeCargos[userDataById?.cargo_id]}
                  />
                </ListItem>
              </List>
            </Grid>

            <Grid item sm={4}>
              <List>
                <ListItem>
                  <ListItemText
                    classes={{
                      primary: classes.primary,
                      secondary: classes.secundary,
                    }}
                    primary={strings.adminInfo}
                    secondary={PerfilAdmin[userDataById?.perfil_admin]}
                  />
                </ListItem>
              </List>
            </Grid>

            <Grid item sm={4}>
              <List>
                <ListItem>
                  <ListItemText
                    classes={{
                      primary: classes.primary,
                      secondary: classes.secundary,
                    }}
                    primary={strings.agpro}
                    secondary={TypeAgpro[userDataById?.plantupper]}
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <EditUser ref={editUserRef} />
    </Grid>
  )
}

export default UserDataInfo
