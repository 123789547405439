/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect } from 'react'

import { Grid, Paper, Typography, AppBar, Tabs, Tab } from '@material-ui/core'

import Copyright from '~/components/copyright'
import { useValidationStore } from '~/store/validation/validation-store'

import ModifiedCity from './components/modified-city-grid'
import { TablePanel } from './components/tab-panel'
import ValidationAll from './components/validation-all-grid'
import strings from './strings'
import { useStyles } from './style'

const Validation: React.FC = () => {
  const classes = useStyles()
  const { setNavigation, aba, listValidation, listModifiedCity } = useValidationStore()
  const tabsHandleControl = (index: any) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const handleTabsControl = (event: React.ChangeEvent<{}>, newValue: number) => {
    setNavigation(newValue)
  }

  useEffect(() => {
    listValidation()
    listModifiedCity()
  }, [])

  return (
    <Grid item xs={12} className={classes.gridMain}>
      <Paper elevation={0} className={classes.paper}>
        <Grid
          item
          xs={12}
          container
          style={{ marginBottom: '1.3rem', justifyContent: 'space-between' }}
        >
          <Typography variant="h4" color="primary">
            {strings.growCropsPage.title}
          </Typography>
        </Grid>
        <AppBar position="static">
          <Tabs value={aba} onChange={handleTabsControl}>
            <Tab label="Todos" {...tabsHandleControl(0)} />
            <Tab label="Não Validado" {...tabsHandleControl(1)} />
            <Tab label="Cidade Modificada" {...tabsHandleControl(2)} />
          </Tabs>
        </AppBar>
        <TablePanel value={aba} index={0}>
          <ValidationAll pages="0" />
        </TablePanel>
        <TablePanel value={aba} index={1}>
          <ValidationAll pages="1" />
        </TablePanel>
        <TablePanel value={aba} index={2}>
          <ModifiedCity />
        </TablePanel>
      </Paper>

      <Grid item xs={12} className={classes.gridCopyright}>
        <Copyright />
      </Grid>
    </Grid>
  )
}

export default Validation
