const strings = {
  welcome: 'Seja bem vindo(a)',
  presentation: {
    start: 'Essa é a nova cara do ',
    middle: 'PlantUP',
    end: ' Administrativo, mais rápido, moderno e simplificado.',
  },
  fastAccess: 'Acesso Rápido.',
  fastAccessConsultancy: 'Acesse, gerencie e altere a consultoria e seus dados agora mesmo!',
  fastAccessClient: 'Acesse, gerencie e altere os agricultores e seus dados agora mesmo!',
  fastAccessUser: 'Acesse, gerencie e altere os usuários e seus dados agora mesmo!',
  fastAccessAudit: 'Acesse, gerencie e altere as auditorias e seus dados agora mesmo!',
  fastAccessCtvs: 'Acesse, gerencie e altere os acessos de CTVs e seus dados agora mesmo!',
  fastAccessCultivar: 'Acesse, gerencie e altere as cultivares e seus dados agora mesmo!',
  fastAccessRegiao: 'Acesse, gerencie e altere as Regiões e seus dados agora mesmo!',
  fastAccessValidation: 'Acesse, gerencie e altere as validações e seus dados agora mesmo!',
}

export default strings
