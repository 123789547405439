import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { Grid, Paper, Typography, Button } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'

import { useClientStore } from '~/store/client/client-store'

import AgproClient from './components/agpro-client'
import ContactsMade from './components/contacts-made'
import DetailFarm from './components/details-farm'
import EquipeClient from './components/equipe-client'
import InfoFarmer from './components/info-client'
import strings from './strings'
import { useStyles } from './styles'

type Params = {
  cliente_id: string
}

const ClientDetails: React.FC = () => {
  const classes = useStyles()
  const { cliente_id } = useParams<Params>()
  const getClientById = useClientStore((state) => state.getClientById)
  const { client: clientData } = useClientStore()

  const history = useHistory()

  useEffect(() => {
    getClientById(cliente_id)
  }, [])

  return (
    <Grid item xs={12} className={classes.gridMain}>
      <Paper elevation={0} className={classes.paper}>
        <Grid container item xs={12}>
          <Grid item xs={1}>
            <Button
              id="button-cliente-back"
              variant="contained"
              color="primary"
              className={classes.iconBack}
              onClick={() => history.goBack()}
            >
              <ArrowBack />
            </Button>
          </Grid>
          <Grid item xs>
            <Typography variant="h4" color="primary">
              {clientData?.nome}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} className={classes.bodyInformation}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {clientData && <InfoFarmer dadosClient={clientData} />}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" color="primary">
                <DetailFarm cliente_id={cliente_id} />
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <EquipeClient
                cliente_id={cliente_id}
                filtro="plantupper=0"
                type={1}
                title={strings.team}
              />
            </Grid>
            <Grid item xs={12}>
              <EquipeClient
                cliente_id={cliente_id}
                filtro="plantupper=1&perfil_admin=4"
                type={2}
                title={strings.ctv}
              />
            </Grid>
            <Grid item xs={12}>
              <AgproClient cliente_id={cliente_id} title={strings.agpro} />
            </Grid>
            <Grid item xs={12}>
              <ContactsMade cliente_id={cliente_id} />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default ClientDetails
