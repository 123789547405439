import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Grid, Paper, Typography, Button } from '@material-ui/core'
import { GridColDef, DataGrid, GridColumnHeaderParams } from '@material-ui/data-grid'
import { VerifiedUser } from '@material-ui/icons'

import Copyright from '~/components/copyright'
import SearchDataGrid from '~/components/search-data-grid'
import { AppRoutePath } from '~/constants/paths'
import { GRID_DEFAULT_LOCALE_TEXT } from '~/constants/translate-data-grid'
import { Premium } from '~/enums/enum'
import { useClientStore } from '~/store/client/client-store'
import { useFilterClientState } from '~/store/client/filter-client'
import { CpfCnpjMask } from '~/utils'

import { CadastrouEquipe } from './enum'
import strings from './strings'
import { useStyles } from './styles'

const Client: React.FC = () => {
  const classes = useStyles()
  const { items } = useFilterClientState()
  const setFilter = useFilterClientState((state) => state.setFilter)

  const { clientList, isLoading } = useClientStore()
  const load = useClientStore((state) => state.load)
  const columns: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'Agricultor',
      width: 150,
      flex: 0.3,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'documento',
      headerName: 'Documento',
      width: 150,
      flex: 0.2,
      renderCell: (params: any) => (params.value ? CpfCnpjMask(params.value) : '-'),
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'origem',
      headerName: 'Origem',
      width: 180,
      flex: 0.15,
      renderCell: (params: any) => (
        <p style={{ color: params.value === 'Protege' ? '#20aee3' : '#ff9041' }}>{params.value}</p>
      ),
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'descricao_etapa',
      headerName: 'Etapa',
      width: 150,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'cadastrou_equipe',
      headerName: 'Equipe',
      width: 150,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => (
        <p
          style={{
            color: params.value === CadastrouEquipe[1] ? 'green' : 'red',
          }}
        >
          {params.value}
        </p>
      ),
      valueGetter: (params) => CadastrouEquipe[params?.row?.cadastrou_equipe],
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'cadastrou_ctv',
      headerName: 'CTV',
      width: 150,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => params.value ?? '-',
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'premium',
      headerName: 'Cliente PlantUP IA',
      width: 150,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => (
        <p style={{ color: params.row?.premium === '1' ? 'green' : 'red' }}>
          {params.value.toUpperCase()}
        </p>
      ),
      valueGetter: (params) => Premium[params.row?.premium],
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'id',
      headerName: 'Ações',
      description: 'Abre os detalhes do agricultor',
      flex: 0.1,
      minWidth: 150,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => (
        <Link to={`${AppRoutePath.CLIENTDETAIL.replace(':cliente_id', params.value)}`}>
          <Button variant="contained" color="primary" size="small" endIcon={<VerifiedUser />}>
            {strings.subtitle}
          </Button>
        </Link>
      ),
    },
  ]

  useEffect(() => {
    load()
  }, [])

  return (
    <Grid item xs={12} className={classes.gridMain}>
      <Paper elevation={0} className={classes.paper}>
        <Grid container item xs={12} className={classes.titleStyle}>
          <Typography variant="h4" color="primary">
            {strings.title}
          </Typography>
        </Grid>
        <Grid container item sm={12} xs={12} className={classes.resumeAuditTable}>
          <DataGrid
            loading={isLoading}
            rows={clientList}
            columns={columns}
            filterModel={{ items }}
            onFilterModelChange={(model) => setFilter(model)}
            localeText={GRID_DEFAULT_LOCALE_TEXT}
          />
        </Grid>
      </Paper>
      <Copyright />
    </Grid>
  )
}

export default Client
