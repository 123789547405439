import React, { forwardRef, useState, useRef, useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'
import MaterialTable, { Column as MaterialTableColumn } from '@material-table/core'
import { Button, Grid, Paper, Typography } from '@material-ui/core'
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from '@material-ui/icons'
import HistoryIcon from '@material-ui/icons/History'

import { TypeSafraEmuns, CultureCodesEnums } from '~/enums/'
import { useValidationStore } from '~/store/validation/validation-store'
import { IValidationData } from '~/types/validation-types'

import { ChangeHistory } from './components/change-history'
import { FarmerGrid } from './components/farme-grid'
import { useStyles } from './styles'

interface Column<RowData extends object> extends MaterialTableColumn<RowData> {
  title: string
}

type TableData = IValidationData & { id: string }

export const ValidationData: React.FC = () => {
  const classes = useStyles()
  const { validationData, isLoading, getHistory } = useValidationStore()
  const [modalState, setModalState] = useState<boolean>(false)
  const alterSingleTable = () => {
    if (tableRef.current.dataManager !== null) {
      tableRef.current.dataManager.detailPanelType = 'single'
    }
    tableRef.current.dataManager.detailPanelType = 'multiple'
  }
  const [history, setHistory] = useState<any>([])
  const tableRef = useRef<any>(0)
  const formatNumber = (number: number) => {
    return number.toLocaleString('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }

  const renderTipoSafra = useMemo(
    () =>
      ({ tipo_safra }: TableData) => {
        return <span>{tipo_safra ? TypeSafraEmuns[tipo_safra] : '-'}</span>
      },
    [],
  )

  const renderCultura = useMemo(
    () =>
      ({ cultura_id }: TableData) => {
        return <span>{cultura_id ? CultureCodesEnums[cultura_id] : '-'}</span>
      },
    [],
  )

  const renderQtdeAreaPlantada = useMemo(
    () =>
      ({ qtde_area_plantada }: TableData) => {
        return <span>{qtde_area_plantada ? formatNumber(Number(qtde_area_plantada)) : '-'}</span>
      },
    [],
  )

  const renderVariedadesFazenda = useMemo(
    () =>
      ({ mensagem_validacao }: TableData) => {
        return <span>{mensagem_validacao?.varieades_fazenda || '- | -'}</span>
      },
    [],
  )

  const renderMinimoVariedades = useMemo(
    () =>
      ({ mensagem_validacao }: TableData) => {
        return <span>{mensagem_validacao?.minimo_variedades || '- | -'}</span>
      },
    [],
  )

  const renderDataPlantioMinimo = useMemo(
    () =>
      ({ mensagem_validacao }: TableData) => {
        return (
          <span>
            {mensagem_validacao?.data_plantio_minimo && mensagem_validacao?.data_plantio_fazenda
              ? `${mensagem_validacao?.data_plantio_fazenda} | ${mensagem_validacao?.data_plantio_minimo}`
              : '- | -'}
          </span>
        )
      },
    [],
  )

  const renderDataColheitaMinimo = useMemo(
    () =>
      ({ mensagem_validacao }: TableData) => {
        return (
          <span>
            {mensagem_validacao?.data_colheita_minimo && mensagem_validacao?.data_colheita_fazenda
              ? `${mensagem_validacao?.data_colheita_fazenda} | ${mensagem_validacao?.data_colheita_minimo}`
              : '- | -'}
          </span>
        )
      },
    [],
  )

  const renderAlterouSituacao = useMemo(
    () => (rowData: TableData) => {
      // eslint-disable-next-line react/destructuring-assignment
      return Number(rowData.alterou_situacao) > 0 ? (
        <Button
          variant="contained"
          style={{
            backgroundColor: '#48bbe6',
            color: '#fff',
            fontSize: '10px',
          }}
          onClick={async () => {
            setModalState(true)
            const data = await getHistory(rowData)
            setHistory(data)
          }}
        >
          <HistoryIcon />
        </Button>
      ) : (
        <span>Sem Alteração</span>
      )
    },
    [],
  )

  const columns: Column<TableData>[] = [
    {
      title: 'Propriedade',
      field: 'propriedade',
      cellStyle: {
        whiteSpace: 'nowrap',
        alignItems: 'center',
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        fontWeight: 500,
        fontSize: 14,
      },
    },
    {
      title: 'Safra',
      field: 'desc_safra',
      cellStyle: {
        whiteSpace: 'nowrap',
        alignItems: 'center',
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        fontWeight: 500,
        fontSize: 14,
      },
    },
    {
      title: 'Usuário Cadastrou',
      field: 'usuario_cadastrou',
      cellStyle: {
        whiteSpace: 'nowrap',
        alignItems: 'center',
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        fontWeight: 500,
        fontSize: 14,
      },
    },
    {
      title: 'Status',
      field: 'desc_situacao',
      cellStyle: {
        whiteSpace: 'nowrap',
        alignItems: 'center',
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        fontWeight: 500,
        fontSize: 14,
      },
    },
    {
      title: 'Alterado',
      field: 'alterado',
      cellStyle: {
        whiteSpace: 'nowrap',
        alignItems: 'center',
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        fontWeight: 500,
        fontSize: 14,
      },
    },
    {
      title: 'Tipo Usuário',
      field: 'tipo_usuario',
      cellStyle: {
        whiteSpace: 'nowrap',
        alignItems: 'center',
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
        fontWeight: 500,
        fontSize: 14,
      },
    },
  ]

  const memoizedColumns: Column<TableData>[] = useMemo(
    () => [
      ...columns,
      {
        title: 'Tipo Safra',
        field: 'tipo_safra',
        cellStyle: {
          whiteSpace: 'nowrap',
          alignItems: 'center',
          textAlign: 'center',
        },
        render: renderTipoSafra,
        headerStyle: {
          textAlign: 'center',
          fontWeight: 500,
          fontSize: 14,
        },
      },
      {
        title: 'Cultura',
        field: 'cultura_id',
        cellStyle: {
          whiteSpace: 'nowrap',
          alignItems: 'center',
          textAlign: 'center',
        },
        render: renderCultura,
        headerStyle: {
          textAlign: 'center',
          fontWeight: 500,
          fontSize: 14,
        },
      },
      {
        title: 'Área',
        field: 'qtde_area_plantada',
        cellStyle: {
          whiteSpace: 'nowrap',
          alignItems: 'center',
          textAlign: 'center',
        },
        render: renderQtdeAreaPlantada,
        headerStyle: {
          textAlign: 'center',
          fontWeight: 500,
          fontSize: 14,
        },
      },
      {
        title: 'Variedades\n(L | M)',
        field: 'mensagem_validacao.minimo_variedades',
        cellStyle: {
          whiteSpace: 'nowrap',
          alignItems: 'center',
          textAlign: 'center',
        },
        render: renderVariedadesFazenda,
        headerStyle: {
          textAlign: 'right',
          fontWeight: 500,
          fontSize: 14,
          whiteSpace: 'pre-line',
        },
      },
      {
        title: 'Variedades Fazenda',
        field: 'mensagem_validacao.varieades_fazenda',
        cellStyle: {
          whiteSpace: 'nowrap',
          alignItems: 'center',
          textAlign: 'center',
        },
        render: renderMinimoVariedades,
        headerStyle: {
          textAlign: 'right',
          fontWeight: 500,
          fontSize: 14,
          whiteSpace: 'pre-line',
        },
      },
      {
        title: 'Plantio\n(L | M)',
        field: 'mensagem_validacao.data_plantio_minimo',
        cellStyle: {
          whiteSpace: 'nowrap',
          alignItems: 'center',
          textAlign: 'center',
        },
        render: renderDataPlantioMinimo,
        headerStyle: {
          textAlign: 'right',
          fontWeight: 500,
          fontSize: 14,
          whiteSpace: 'pre-line',
        },
      },
      {
        title: 'Colheita\n(L | M)',
        field: 'mensagem_validacao.data_colheita_minimo',
        cellStyle: {
          whiteSpace: 'nowrap',
          alignItems: 'center',
          textAlign: 'center',
        },
        render: renderDataColheitaMinimo,
        headerStyle: {
          textAlign: 'right',
          fontWeight: 500,
          fontSize: 14,
          whiteSpace: 'pre-line',
        },
      },
      {
        title: 'Hístorico de alteração',
        field: 'alterou_situacao',
        render: renderAlterouSituacao,
        cellStyle: {
          whiteSpace: 'nowrap',
          alignItems: 'center',
          textAlign: 'center',
        },
        headerStyle: {
          textAlign: 'center',
          fontWeight: 500,
          fontSize: 14,
        },
      },
    ],
    [
      renderTipoSafra,
      renderCultura,
      renderQtdeAreaPlantada,
      renderVariedadesFazenda,
      renderMinimoVariedades,
      renderDataPlantioMinimo,
      renderDataColheitaMinimo,
      renderAlterouSituacao,
    ],
  )

  return (
    <>
      <Grid item sm={12} xs={12}>
        <Paper variant="outlined" className={`${classes.paper}`}>
          {isLoading ? (
            <Skeleton height={300} style={{ borderRadius: '11px' }} />
          ) : (
            <>
              <Grid item sm={12} xs={12} className={classes.headerContainer}>
                <Grid item sm={12}>
                  <Typography variant="h6" className={classes.typography}>
                    Dados Para Validação
                  </Typography>
                </Grid>
              </Grid>
              <Grid item sm={12} xs={12} style={{ overflowX: 'auto' }}>
                <MaterialTable<TableData>
                  data={validationData.map((item, index) => ({ ...item, id: index.toString() }))}
                  columns={memoizedColumns}
                  style={{
                    overflowX: 'auto',
                    width: '100%',
                    display: 'grid',
                    gridTemplateRows: 'auto 1fr auto',
                  }}
                  tableRef={tableRef}
                  icons={{
                    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
                    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
                    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
                    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
                    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
                    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
                    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
                    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
                    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
                    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
                    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
                    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
                    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
                    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
                    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
                    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
                    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
                  }}
                  detailPanel={[
                    {
                      tooltip: 'Acessar Fazenda',
                      render: (rowData: any) => {
                        return (
                          <Grid container style={{ background: '#f2eff0' }}>
                            <FarmerGrid
                              talhoes={rowData?.rowData?.talhoes}
                              alterChangeSingleDetailPainel={alterSingleTable}
                            />
                          </Grid>
                        )
                      },
                    },
                  ]}
                  localization={{
                    toolbar: {
                      searchPlaceholder: 'Pesquisar',
                    },
                  }}
                  options={{
                    grouping: false,
                    showTitle: false,
                    defaultExpanded: true,
                    headerStyle: {
                      whiteSpace: 'nowrap',
                    },
                  }}
                />
              </Grid>
            </>
          )}
        </Paper>
      </Grid>
      <ChangeHistory state={modalState} action={() => setModalState(false)} history={history} />
    </>
  )
}
