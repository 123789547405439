import React, { ReactElement, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Grid, Paper, Typography, Button, TextField } from '@material-ui/core'
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridCellParams,
  GridValueGetterParams,
} from '@material-ui/data-grid'
import { Add } from '@material-ui/icons'
import { AxiosError } from 'axios'

import { GRID_DEFAULT_LOCALE_TEXT } from '~/constants/translate-data-grid'
import { useAlert } from '~/hooks/message'
import { api } from '~/services/api'
import { useStoreMissions, PropsMissions } from '~/store/missions-store'

import strings from './strings'
import { useStyles } from './styles'

type PropsRows = {
  id?: number
  safra: string
  safra_id: number
  cultura_id: number
  observacao: string
  nome: string
  tipo_fonte: number
  meio_interacao: string
  etapa_interacao: string
}

type ParamsRoute = {
  cliente_id: string
}

type Props = {
  setOpenModal: (visible: boolean) => void
  reloadAuxiliary: number
  filtros: any
}

type CustomToolbarProps = {
  onClick: () => void
}

const columnsSource = (missions: PropsMissions[]): GridColDef[] => [
  { field: 'id', hide: true },
  {
    field: 'safra',
    headerName: 'Missão',
    flex: 0.2,
    renderCell: (params: GridCellParams) => (
      <Typography component="span" variant="subtitle2">
        {missions &&
          missions.map((value) => {
            const { row } = params
            const { mission_detail } = value
            if (
              row?.safra_id === mission_detail[0]?.safra_id &&
              row?.cultura_id === mission_detail[0]?.cultura_id
            )
              return value.titulo
            return ''
          })}
      </Typography>
    ),
    valueGetter: (params: GridValueGetterParams) => {
      return (
        missions &&
        missions.map((value) => {
          const { row } = params
          const { mission_detail } = value
          if (
            row?.safra_id === mission_detail[0]?.safra_id &&
            row?.cultura_id === mission_detail[0]?.cultura_id
          )
            return value.titulo
          return ''
        })
      )
    },
  },
  {
    field: 'tipo_fonte',
    headerName: 'Tipo de Fonte',
    flex: 0.2,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'nome',
    headerName: 'Nome',
    flex: 0.2,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'meio_interacao',
    headerName: 'Meio interação',
    flex: 0.2,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'etapa_interacao',
    headerName: 'Etapa Interação',
    flex: 0.2,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'observacao',
    headerName: 'Observações',
    flex: 0.3,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      return (
        <TextField
          value={params.row?.observacao}
          InputProps={{ disableUnderline: true }}
          multiline
          style={{ width: '100%', height: '100%' }}
        />
      )
    },
  },
]

function CustomToolbar({ onClick }: CustomToolbarProps) {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <Grid item xs style={{ textAlign: 'right' }}>
        <Button
          id="button-nova-interacao"
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={onClick}
        >
          {strings.auxiliarySource.newInteration}
        </Button>
      </Grid>
    </GridToolbarContainer>
  )
}

function GridInteractionAuxiliary(props: Props): ReactElement {
  const classes = useStyles()
  const { cliente_id } = useParams<ParamsRoute>()
  const { setOpenModal, reloadAuxiliary } = props
  const { missions } = useStoreMissions()
  const alert = useAlert()
  const { filtros } = props

  const [rows, setRows] = useState<PropsRows[]>([])

  useEffect(() => {
    api
      .get(`/interaction/auxiliary/${cliente_id}`)
      .then((res: any) => {
        res.data.map((r: any, i: any) => {
          r.id = i
          return r
        })
        setRows(res.data)
      })
      .catch((error: AxiosError) =>
        alert.show({ message: error.response?.data.message, type: 'error' }),
      )
  }, [reloadAuxiliary])

  return (
    <Grid item xs={12} sm={12} container>
      <Paper variant="outlined" className={`${classes.paper} ${classes.paperSummaryAudit}`}>
        <Typography variant="h5" className={classes.textTitleCard}>
          {strings.auxiliarySource.title}
        </Typography>

        <DataGrid
          loading={false}
          rows={rows}
          columns={columnsSource(missions)}
          pageSize={25}
          disableSelectionOnClick
          localeText={GRID_DEFAULT_LOCALE_TEXT}
          autoHeight
          rowHeight={60}
          {...filtros}
          components={{
            Toolbar: () => <CustomToolbar onClick={() => setOpenModal(true)} />,
          }}
        />
      </Paper>
    </Grid>
  )
}

export default GridInteractionAuxiliary
