import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  gridMain: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '24px 24px 24px 24px',
    flexGrow: 1,
    minHeight: 696,
    height: '100%',
  },
  paper: {
    flex: 1,
    height: '100%',
    width: '100%',
    textAlign: 'center',
    flexDirection: 'column',
    color: theme.palette.text.secondary,
    borderRadius: '16px',
  },
  resumeAuditTable: {
    flex: 1,
    display: 'flex',
    height: '80vh',
    padding: '8px 16px',
  },
  titleStyle: {
    textAlign: 'left',
    margin: '10px 20px',
  },
}))
