import React from 'react'
import { Link } from 'react-router-dom'

import { Button, Chip } from '@material-ui/core'
import { DataGrid, GridColDef, GridColumnHeaderParams } from '@material-ui/data-grid'
import VisibilityIcon from '@material-ui/icons/Visibility'

import SearchDataGrid from '~/components/search-data-grid'
import { allResults } from '~/constants/data-field'
import { AppRoutePath } from '~/constants/paths'
import { GRID_DEFAULT_LOCALE_TEXT } from '~/constants/translate-data-grid'
import { useFilterAuditState } from '~/store/audit/filter-audit'
import { useValidationStore } from '~/store/validation/validation-store'
import { maskInterceptor } from '~/utils/mask-interceptor'

const ModifiedCity: React.FC = () => {
  const { items } = useFilterAuditState()
  const setFilter = useFilterAuditState((state) => state.setFilter)
  // atenção listModifiedCity não está sendo utilizada, porque ?
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { modifiedCityList, findClientById, setSafraField, listModifiedCity } = useValidationStore()

  const formatNumber = (number: number) => {
    return number.toLocaleString('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }
  const columns: GridColDef[] = [
    {
      field: 'cliente_nome',
      headerName: 'Agricultor',
      flex: 0.2,
      width: 150,
      minWidth: 120,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },

    {
      field: 'cpf_cnpj_agricultor',
      headerName: 'CPF/CNPJ',
      width: 200,
      renderCell: (params) =>
        params?.row?.cpf_cnpj_agricultor.length === 11
          ? maskInterceptor('cpf')(params.row?.cpf_cnpj_agricultor)
          : maskInterceptor('cnpj')(params.row?.cpf_cnpj_agricultor),
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'celular_agricultor',
      headerName: 'Telefone',
      width: 120,
      flex: 0.2,
      renderCell: (params) => maskInterceptor('phone')(params.row?.celular_agricultor),
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 140,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      minWidth: 140,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'qtde_total',
      headerName: 'Área(ha)',
      width: 120,
      flex: 0.3,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumber(Number(params.row?.qtde_total)),
      valueGetter: (params) => {
        const value = Number(params.row?.qtde_total)
        return value
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'ultima_data_envio_validacao',
      headerName: 'Data último envio',
      flex: 0.3,
      minWidth: 110,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const date = new Date(params.row?.ultima_data_envio_validacao)
        const parseDate = new Intl.DateTimeFormat('pt-br').format(date)
        return parseDate
      },
      valueGetter: (params) => {
        const date = new Date(params.row?.ultima_data_envio_validacao)
        const parseDate = new Intl.DateTimeFormat('pt-br').format(date)
        return parseDate
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'descricao_situacao',
      headerName: 'Agricultor Safra',
      flex: 0.3,
      minWidth: 110,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
      renderCell: (params) => {
        return (
          <Chip
            id="chips-status"
            label={params.row?.descricao_situacao}
            style={{ backgroundColor: '#FF9041', color: '#fff' }}
          />
        )
      },
    },
    {
      field: 'descricao_alterado_por',
      headerName: 'Alterado por',
      flex: 0.3,
      minWidth: 130,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => maskInterceptor('phone')(params.row?.celular),
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'acoes',
      headerName: 'Exibir',
      flex: 0.1,
      minWidth: 130,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <Link
            to={`${AppRoutePath.VALIDATIONDETAIL.replace(':validationId', params?.row.cliente_id)}`}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: '#48bbe6',
                color: '#fff',
                fontSize: '10px',
              }}
              onClick={() => {
                findClientById(params?.row.cliente_id)
                setSafraField(allResults)
              }}
            >
              <VisibilityIcon />
            </Button>
          </Link>
        )
      },
    },
  ]

  return (
    <DataGrid
      rows={modifiedCityList}
      columns={columns}
      style={{
        maxHeight: '66vh',
        minHeight: '66vh',
        height: '100%',
      }}
      disableSelectionOnClick
      filterModel={{ items }}
      sortingMode="client"
      getRowId={(row) => row.cliente_id}
      onFilterModelChange={(model) => setFilter(model)}
      localeText={GRID_DEFAULT_LOCALE_TEXT}
    />
  )
}
export default ModifiedCity
