import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { Grid, Paper, Typography, Button } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'

import { useConsultacyStore } from '~/store/consultancy/consultancy-store'

import ConsultancyClient from './components/consultancy-client'
import ConsultancyData from './components/consultancy-data'
import UserConsultancy from './components/user-consultancy'
import { useStyles } from './style'

type Params = {
  consultancyId: string
}

const ConsultancyDetails: React.FC = () => {
  const classes = useStyles()
  const { consultancyId } = useParams<Params>()
  const getConsultacyById = useConsultacyStore((state) => state.consultancyById)
  const getConsultacyWithClient = useConsultacyStore((state) => state.clientConsultancy)
  const getConsultacyWithUser = useConsultacyStore((state) => state.userConsultancy)

  const { Consultacy, aba, setNavigation } = useConsultacyStore()
  const history = useHistory()

  useEffect(() => {
    getConsultacyById(consultancyId)
    getConsultacyWithClient(consultancyId)
    getConsultacyWithUser(consultancyId)
  }, [])

  return (
    <Grid item xs={12} className={classes.gridMain}>
      <Paper elevation={0} className={classes.paper}>
        <Grid container item xs={12}>
          <Grid item xs={1}>
            <Button
              variant="contained"
              color="primary"
              className={classes.iconBack}
              onClick={() => {
                history.goBack()
                setNavigation(aba)
              }}
            >
              <ArrowBack />
            </Button>
          </Grid>
          <Grid item xs>
            <Typography variant="h4" color="primary">
              {Consultacy?.nome}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} className={classes.bodyInformation}>
          <ConsultancyData />
        </Grid>
        <Grid container item xs={12} className={classes.bodyInformation}>
          <UserConsultancy />
        </Grid>
        <Grid container item xs={12} className={classes.bodyInformation}>
          <ConsultancyClient />
        </Grid>
      </Paper>
    </Grid>
  )
}

export default ConsultancyDetails
