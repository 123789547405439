const strings = {
  title: 'Editar Cliente',
  document: 'CPF/CNPJ',
  mail: 'E-mail',
  phone: 'Celular',
  status: 'Situação',
  premium: 'Cliente PlantUP IA',
  street: 'Rua',
  district: 'Bairro',
  houseNumber: 'Nº',
  cep: 'CEP',
}

export default strings
