import Swal from 'sweetalert2'
import create from 'zustand'

import { api } from '~/services/api'
import { TeamProps } from '~/types/team-types'

type TeamState = {
  isLoading: boolean
  messageError: any
  listEquipe: TeamProps[]
  typeRequest?: number
}

type ResponseSuccess = {
  message: string
}

type TeamActions = {
  getEquipeByClientId: (clientId: number | string, filtros: any) => Promise<TeamProps[]>
  deleteUserCliente: (clientId: number | string, userId: number) => Promise<any>
  addToTeam: (clientId: number | string, userId: number, type: number) => Promise<any>
}

type State = TeamState & TeamActions

const initialState: TeamState = {
  isLoading: false,
  messageError: [],
  listEquipe: [],
}

export const useTeamStore = create<State>((set) => ({
  ...initialState,
  getEquipeByClientId: async (clientId, filtros) => {
    set({ isLoading: true })
    try {
      const { data: listEquipe } = await api.get<TeamProps[]>(
        `/users/client/${clientId}?${filtros}`,
      )
      set({ listEquipe })
      return listEquipe
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Opss, Algo erro acontenceu!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }
    return []
  },
  deleteUserCliente: async (clientId, userId) => {
    set({ isLoading: true })
    try {
      const resp = await api.delete<ResponseSuccess>(
        `/client/link/user?cliente_id=${clientId}&usuario_id=${userId}`,
      )
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: resp.data.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Opss, Algo erro acontenceu!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }
  },
  addToTeam: async (clientId, userId, type) => {
    set({ isLoading: true, messageError: '', typeRequest: type })
    try {
      const resp = await api.post<ResponseSuccess>(
        `/client/link/user?cliente_id=${clientId}&usuario_id=${userId}`,
      )
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: resp.data.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } catch (error: any) {
      set({ messageError: error?.response?.data?.errors })
    } finally {
      set({ isLoading: false })
    }
  },
}))
