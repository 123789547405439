/* eslint-disable import-helpers/order-imports */
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import MuiPhoneNumber from 'material-ui-phone-number-2'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, TextField, MenuItem, Fade, FormControl } from '@material-ui/core'
import * as yup from 'yup'

import 'react-phone-input-2/lib/material.css'

import _ from 'lodash'

import Modal from '~/components/modal'
import {
  Cargo,
  PerfilAdmin,
  Status,
  agpro,
  dataPais,
  DefaultInput,
  associationAdminField,
  associationMemberField,
  associationMemberYesOption,
} from '~/constants/data-field'
import { userCreateMapper } from '~/mappers/user-mapper'
import { useUserStore } from '~/store/user/user-store'
import {
  PropsFormCreateUser,
  FormsHandleCreate,
  RequestDataUser,
} from '~/types/user-types/user-types'
import { maskInterceptor } from '~/utils/mask-interceptor'

import strings from './strings'
import { useStyles } from './style'

const schema = yup.object({
  email: yup.string().email('Formato de email, inválido.').required('E-mail é obrigatório'),
  cpf: yup.string().when('pais_id', {
    is: (value: any) => value === '1' ?? value,
    then: yup.string().required('CPF é obrigatório'),
    otherwise: yup.string().nullable(),
  }),
  celular: yup.string().required('Celular é obrigatório'),
  nome: yup.string().required('Nome é obrigatório'),
  password: yup.string().required('Senha é obrigatório'),
  password_confirmation: yup.string().required('Campo é obrigatório'),
  status: yup.string().required('Campo é obrigatório'),
  plantupper: yup.string().required('Campo é obrigatório'),
  cargo_id: yup.string().required('Campo é obrigatório'),
  associado: yup.string().required('Campo é obrigatório'),
  perfil_id: yup.string(),
})

const CreateUser: React.ForwardRefRenderFunction<FormsHandleCreate> = (props, ref) => {
  const classes = useStyles()
  const createUser = useUserStore((state) => state.createUser)
  const [countryCode, setcountryCode] = useState<string | undefined>(undefined)
  const [isAssociationAdminDisplayed, setIsAssociationAdminDisplayed] = useState<boolean>(false)

  const {
    isLoading,
    messageError,
    clearFormsCreateUser,
    controllerModalState,
    modalControllerChangeCreate,
  } = useUserStore()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    resetField,
  } = useForm<PropsFormCreateUser>({
    resolver: yupResolver(schema),
  })
  const onSubmit: SubmitHandler<PropsFormCreateUser> = async (usersData) => {
    const userMapper = userCreateMapper(usersData)
    const userData = _.omitBy(userMapper, _.isNil) as RequestDataUser
    createUser(userData)
  }
  const closeModal = () => {
    modalControllerChangeCreate(false)
    clearFormsCreateUser()
    setcountryCode(undefined)
    setIsAssociationAdminDisplayed(false)
  }
  useImperativeHandle(ref, () => {
    return {
      openModalCreateForms() {
        modalControllerChangeCreate(true)
        reset()
        setcountryCode(undefined)
        setIsAssociationAdminDisplayed(false)
      },
    }
  })
  return (
    <Modal
      open={controllerModalState}
      onClose={closeModal}
      title={strings.textModal.addModalTitle}
      onSave={handleSubmit(onSubmit)}
      save
      isLoad={isLoading}
      disableCloseClickOut
      scroll
    >
      <Fade in={controllerModalState}>
        <FormControl
          onSubmit={handleSubmit(onSubmit)}
          variant="outlined"
          className={classes.formStyle}
        >
          <Grid container spacing={2} className={classes.modalStyle}>
            <Grid item sm={12}>
              <Controller
                name="pais_id"
                control={control}
                defaultValue={DefaultInput.paisDefault}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    select
                    id="pais_id"
                    variant="outlined"
                    label="Pais"
                    className={classes.textInput}
                    onChange={(value) => {
                      field.onChange(value)
                      setcountryCode(field.value)
                      resetField('cpf')
                    }}
                  >
                    {dataPais.map((Option) => (
                      <MenuItem key={Option.id_pais} value={Option.id_pais}>
                        {Option.pais}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item sm={countryCode !== '1' ? 7 : 12}>
              <Controller
                name="nome"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="nome"
                    variant="outlined"
                    label="Nome completo"
                    placeholder="Nome do usuário"
                    className={classes.textInput}
                    error={!!errors?.nome}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.nome?.message}
                  />
                )}
              />
            </Grid>
            {countryCode !== '1' ? (
              <Grid item sm={5}>
                <Controller
                  name="cpf"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      id="cpf"
                      variant="outlined"
                      label="CPF"
                      placeholder="CPF"
                      className={classes.textInput}
                      FormHelperTextProps={{
                        className: classes.helperText,
                      }}
                      error={(!!errors?.cpf || !_.isEmpty(messageError?.cpf)) && true}
                      helperText={errors.cpf?.message ?? messageError?.cpf}
                      value={maskInterceptor('cpf')(field.value)}
                    />
                  )}
                />
              </Grid>
            ) : null}
            <Grid item sm={7}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="email"
                    variant="outlined"
                    label="E-mail"
                    placeholder="E-mail"
                    className={classes.textInput}
                    error={(!!errors?.email || !_.isEmpty(messageError?.email)) && true}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.email?.message ?? messageError?.email}
                  />
                )}
              />
            </Grid>

            <Grid item sm={5}>
              <Controller
                name="celular"
                control={control}
                render={({ field }) => (
                  <MuiPhoneNumber
                    {...field}
                    required
                    id="celular"
                    label="celular"
                    defaultCountry="br"
                    masks={{ py: '+... ... ......' }}
                    variant="outlined"
                    onlyCountries={['br', 'py']}
                    error={(!!errors?.celular || !_.isEmpty(messageError?.celular)) && true}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.celular?.message ?? messageError?.celular}
                    InputProps={{
                      style: {
                        borderRadius: 30,
                        width: '100%',
                        color: '#00446B',
                      },
                    }}
                    className={classes.textInput}
                  />
                )}
              />
            </Grid>
            <Grid item sm={6}>
              <Controller
                name="status"
                control={control}
                defaultValue={DefaultInput.statusDefault}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    required
                    id="status"
                    variant="outlined"
                    label="Status"
                    placeholder="Status"
                    className={classes.textInput}
                    error={!!errors?.status}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.status?.message}
                  >
                    {Status.map((Option) => (
                      <MenuItem key={Option.statusValue} value={Option.statusValue}>
                        {Option.statusName}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="cargo_id"
                control={control}
                defaultValue={DefaultInput.CargoDefault}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    required
                    id="cargo"
                    variant="outlined"
                    label="Cargo"
                    placeholder="Selecione uma opção"
                    className={classes.textInput}
                    error={!!errors?.cargo_id}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.cargo_id?.message}
                  >
                    {Cargo.map((Option) => (
                      <MenuItem key={Option.value} value={Option.value}>
                        {Option.nameValue}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="perfil_admin"
                defaultValue={DefaultInput.perfilAdminDefault}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    id="perfil_admin"
                    variant="outlined"
                    label="Perfil Administrativo"
                    placeholder="Perfil Administrativo"
                    className={classes.textInput}
                  >
                    {PerfilAdmin.map((Option) => (
                      <MenuItem key={Option.value} value={Option.value}>
                        {Option.nameValue}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item sm={6}>
              <Controller
                name="plantupper"
                control={control}
                defaultValue={DefaultInput.agProDefault}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    required
                    id="plantupper"
                    variant="outlined"
                    label="AgPro"
                    placeholder="Não"
                    className={classes.textInput}
                    error={(!!errors?.plantupper || !_.isEmpty(messageError?.plantupper)) && true}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.plantupper?.message ?? messageError?.plantupper}
                  >
                    {agpro.map((Option) => (
                      <MenuItem key={Option.value} value={Option.value}>
                        {Option.nameValue}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item sm={isAssociationAdminDisplayed ? 6 : 12}>
              <Controller
                name="associado"
                control={control}
                defaultValue={DefaultInput.associationMemberDefault}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    required
                    id="associado"
                    variant="outlined"
                    label="Membro de Associação"
                    placeholder="Não"
                    className={classes.textInput}
                    error={!!errors?.associado || !_.isEmpty(messageError?.associado)}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.associado?.message ?? messageError?.associado}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const selectedValue: string = event.target.value
                      const isAssociationMember: boolean =
                        selectedValue === associationMemberYesOption.value

                      field.onChange(selectedValue)
                      setIsAssociationAdminDisplayed(isAssociationMember)

                      if (!isAssociationMember) {
                        resetField('perfil_id')
                      }
                    }}
                  >
                    {associationMemberField.map((Option) => (
                      <MenuItem key={Option.value} value={Option.value}>
                        {Option.nameValue}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            {isAssociationAdminDisplayed && (
              <Grid item sm={6}>
                <Controller
                  name="perfil_id"
                  control={control}
                  defaultValue={DefaultInput.associationAdminDefault}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      required
                      id="perfil_id"
                      variant="outlined"
                      label="Admin. da Associação"
                      placeholder="Não"
                      className={classes.textInput}
                      error={!!errors?.perfil_id || messageError?.perfil_id}
                      FormHelperTextProps={{
                        className: classes.helperText,
                      }}
                      helperText={errors.perfil_id?.message ?? messageError?.perfil_id}
                    >
                      {associationAdminField.map((Option) => (
                        <MenuItem key={Option.value} value={Option.value}>
                          {Option.nameValue}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
            )}
            <Grid item sm={6}>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="password"
                    variant="outlined"
                    label="Senha"
                    placeholder="Senha"
                    className={classes.textInput}
                    type="password"
                    error={(!!errors?.password || !_.isEmpty(messageError?.password)) && true}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.password?.message ?? messageError?.password}
                  />
                )}
              />
            </Grid>
            <Grid item sm={6}>
              <Controller
                name="password_confirmation"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="password_confirmation"
                    variant="outlined"
                    label="Confirmação da senha"
                    placeholder="Confirme sua senha"
                    className={classes.textInput}
                    type="password"
                    error={
                      (!!errors?.password_confirmation ||
                        !_.isEmpty(messageError?.password_confirmation)) &&
                      true
                    }
                    helperText={
                      errors.password_confirmation?.message ?? messageError?.password_confirmation
                    }
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormControl>
      </Fade>
    </Modal>
  )
}

export default forwardRef(CreateUser)
