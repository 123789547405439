import React, { useLayoutEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

import { Grid, Paper, Typography, Button, Chip, LinearProgress } from '@material-ui/core'
import { GridColDef, DataGrid, GridColumnHeaderParams } from '@material-ui/data-grid'
import { VerifiedUser } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'

import Copyright from '~/components/copyright'
import CreateUser from '~/components/create-user'
import EditUser from '~/components/edit-user'
import SearchDataGrid from '~/components/search-data-grid'
import { AppRoutePath } from '~/constants/paths'
import { GRID_DEFAULT_LOCALE_TEXT } from '~/constants/translate-data-grid'
import { PerfilAdmin } from '~/enums/enum'
import { useFilterUserState } from '~/store/user/filter-user'
import { useUserStore } from '~/store/user/user-store'
import { FormsHandleEdit, FormsHandleCreate } from '~/types/user-types/user-types'
import { maskInterceptor } from '~/utils/mask-interceptor'

import strings from './strings'
import { useStyles } from './style'

const Users: React.FC = () => {
  const userList = useUserStore((state) => state.userList)
  const listUsers = useUserStore((state) => state.listUsers)

  useLayoutEffect(() => {
    listUsers()
  }, [listUsers])

  const { isLoadingModal, progress } = useUserStore()

  const classes = useStyles()
  const { items } = useFilterUserState()
  const setFilter = useFilterUserState((state) => state.setFilter)

  const OpenModalCreateUser = () => {
    UserCreateRef.current?.openModalCreateForms()
  }

  const editUserRef = useRef<FormsHandleEdit>(null)
  const UserCreateRef = useRef<FormsHandleCreate>(null)

  const columns: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'Nome',
      flex: 0.2,
      width: 150,
      minWidth: 120,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'perfil_admin',
      headerName: 'Perfil Admin',
      width: 160,
      renderCell: (params) => {
        return PerfilAdmin[params.row?.perfil_admin] ? PerfilAdmin[params.row?.perfil_admin] : ''
      },
      valueGetter: (params) => PerfilAdmin[params.row?.perfil_admin] ?? '',
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'cpf',
      headerName: 'CPF',
      width: 120,
      flex: 0.2,
      renderCell: (params) => maskInterceptor('cpf')(params.row?.cpf),
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 140,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      minWidth: 140,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'celular',
      headerName: 'Celular',
      width: 120,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => maskInterceptor('phone')(params.row?.celular),
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'role',
      headerName: 'Cargo',
      renderCell: (params) => params.row?.role?.nome,
      width: 130,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      minWidth: 130,
      valueGetter: (params) => params.row?.role?.nome,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.2,
      minWidth: 110,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => (params.row?.status === '1' ? 'Ativo' : 'Inativo'),
      renderCell: (params) => {
        return (
          <Chip
            id="chips-status"
            label={params.row?.status === '1' ? 'Ativo' : 'Inativo'}
            style={
              params.row?.status === '1'
                ? { backgroundColor: '#24d2b5', color: '#fff' }
                : { backgroundColor: '#ff5c6c', color: '#fff' }
            }
          />
        )
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} operator="startsWith" />
      ),
    },
    {
      field: 'Ações',
      headerName: 'Ações',
      flex: 0.1,
      minWidth: 130,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <Link to={`${AppRoutePath.USERSDETAIL.replace(':userId', params?.row?.id)}`}>
            <Button variant="contained" color="primary" size="small" endIcon={<VerifiedUser />}>
              {strings.buttonUserTitle}
            </Button>
          </Link>
        )
      },
    },
  ]
  return (
    <Grid item xs={12} className={classes.gridMain}>
      <Paper elevation={0} className={classes.paper}>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          style={{ marginBottom: '1.3rem' }}
        >
          <Typography variant="h4" color="primary">
            {strings.usersPage.title}
          </Typography>
          <Button
            id="button-adicionar-usuario"
            variant="contained"
            endIcon={<AddIcon />}
            style={{
              backgroundColor: '#48bbe6',
              color: '#fff',
              fontSize: '14px',
            }}
            onClick={() => {
              OpenModalCreateUser()
            }}
          >
            {strings.buttonTitle.title}
          </Button>
        </Grid>
        {isLoadingModal ? (
          <LinearProgress variant="determinate" value={progress} />
        ) : (
          <Grid container item sm={12} xs={12} className={classes.resumeAuditTable}>
            <DataGrid
              rows={userList}
              columns={columns}
              disableSelectionOnClick
              filterModel={{ items }}
              onFilterModelChange={(model) => setFilter(model)}
              localeText={GRID_DEFAULT_LOCALE_TEXT}
            />
          </Grid>
        )}
      </Paper>
      <Copyright />
      <EditUser ref={editUserRef} />
      <CreateUser ref={UserCreateRef} />
    </Grid>
  )
}

export default Users
