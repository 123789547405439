/* eslint-disable react-hooks/rules-of-hooks */
import Swal from 'sweetalert2'
import create from 'zustand'
import { persist } from 'zustand/middleware'

import { api } from '~/services/api'
import { PropsFormCreateUser, RequestDataUser } from '~/types/user-types/user-types'

type UserState = {
  isLoading: boolean
  skeletonLoading: boolean
  userWithClient: any
  isLoadingModal: boolean
  messageError: any
  expiresIn: Date
  errorsUsers: boolean
  userList: PropsFormCreateUser[]
  controllerModalState: boolean
  controllerModalStateEdit: boolean
  userDataById: any
  progress: number
}

type UserActions = {
  createUser: (params: RequestDataUser) => Promise<void>
  getUser: (userId: number | string) => Promise<void>
  userRelationshipDelete: (userId: number, AlertSucess: boolean) => Promise<void>
  clearFormsCreateUser: () => void
  update: (
    params: RequestDataUser,
    userId: number,
    callbackUpdateAndRemoveRelationShip?: (userId: number) => void,
  ) => Promise<void>
  list: (filters?: string) => Promise<[]>
  listUsers: () => Promise<void>
  listRelationShipClient: (userId: string) => Promise<void>
  modalControllerChangeCreate: (value: boolean) => void
  modalControllerChangeEdit: (value: boolean) => void
  editDataUserId: (userId: number) => Promise<RequestDataUser>
  removeCtvForCity: (userId: number) => Promise<void>
  removeRelationShipClient: (userId: string, clientId: string) => Promise<void>
  clearUserList: () => void
}

type State = UserState & UserActions

const initialState: UserState = {
  isLoading: false,
  skeletonLoading: false,
  expiresIn: new Date(Date.now() - 86400 * 1000),
  isLoadingModal: false,
  messageError: '',
  errorsUsers: false,
  progress: 0,
  userList: [],
  userWithClient: [],
  controllerModalState: false,
  controllerModalStateEdit: false,
  userDataById: {} as RequestDataUser,
}

export const useUserStore = create<State>(
  persist(
    (set, get) => ({
      ...initialState,
      getUser: async (userId) => {
        const { data } = await api.get(`/users/${userId}`)
        set({ userDataById: data })
      },
      removeRelationShipClient: async (userId, clientId) => {
        const { modalControllerChangeEdit, listRelationShipClient } = get()
        set({ isLoading: true, messageError: '' })
        try {
          await api
            .delete(`/users/client/${clientId}/${userId}`)
            .then((res) => {
              Swal.fire({
                icon: 'success',
                title: 'Removido com sucesso!',
                text: res.data.message,
                confirmButtonColor: '#2b78c0',
                customClass: {
                  container: 'alert-container',
                },
              })
              listRelationShipClient(userId)
            })
            .catch((err) => {
              Swal.fire({
                icon: 'error',
                title: 'Erro Ao Remover vinculo Usuário!',
                text: err.message,
                confirmButtonColor: '#2b78c0',
                customClass: {
                  container: 'alert-container',
                },
              }).then(() => {
                modalControllerChangeEdit(true)
              })
              set({
                isLoading: false,
                messageError: err?.message,
              })
            })
          set({ isLoading: false })
        } catch (error: any) {
          set({
            isLoading: false,
            messageError: error?.response?.data?.message,
          })
        }
      },
      listRelationShipClient: async (userId) => {
        const { data } = await api.get(`/users/relationships/client/${userId}`)
        set({
          userWithClient: data,
          skeletonLoading: false,
        })
      },
      editDataUserId: async (userId) => {
        set({
          isLoadingModal: true,
          messageError: '',
          progress: 98,
        })
        const { data } = await api.get(`/users/${userId}`)
        set({
          isLoadingModal: false,
          progress: 100,
          userDataById: data,
          skeletonLoading: false,
        })
        return data
      },
      clearUserList: () => {
        set({
          userList: [],
        })
      },
      createUser: async (params) => {
        const { clearFormsCreateUser, list, modalControllerChangeCreate } = get()
        set({ isLoading: true, messageError: '' })
        await api
          .post('/users', params)
          .then((resp) => {
            set({
              isLoading: false,
              messageError: '',
              errorsUsers: false,
            })
            Swal.fire({
              icon: 'success',
              title: 'Cadastrado com sucesso!',
              text: resp.data.message,
              confirmButtonColor: '#2b78c0',
              customClass: {
                container: 'alert-container',
              },
            }).then(() => {
              modalControllerChangeCreate(false)
            })
            list()
            clearFormsCreateUser()
          })
          .catch((err) => {
            set({
              isLoading: false,
              messageError: err.response?.data?.errors,
              errorsUsers: true,
            })
          })
      },
      clearFormsCreateUser: () => {
        set({ messageError: '', errorsUsers: false })
      },
      listUsers: async () => {
        try {
          set({ isLoading: true })
          const { data: userList } = await api.get('/users')
          set({
            isLoading: false,
            userList,
          })
        } catch (error: any) {
          set({
            isLoading: false,
            messageError: error?.response?.data?.message,
          })
        }
      },
      userRelationshipDelete: async (userId, alertSucess = true) => {
        const { modalControllerChangeEdit, listRelationShipClient } = get()
        set({ isLoading: true, messageError: '' })
        try {
          await api
            .delete(`/users/${userId}`)
            .then((res) => {
              if (alertSucess) {
                Swal.fire({
                  icon: 'success',
                  title: 'Removido com sucesso!',
                  text: res.data.message,
                  confirmButtonColor: '#2b78c0',
                  customClass: {
                    container: 'alert-container',
                  },
                })
                listRelationShipClient(String(userId))
                set({
                  isLoading: false,
                  messageError: '',
                  skeletonLoading: false,
                })
              }
            })
            .catch((err) => {
              Swal.fire({
                icon: 'error',
                title: 'Erro Ao Remover vinculo Usuário!',
                text: err.message,
                confirmButtonColor: '#2b78c0',
                customClass: {
                  container: 'alert-container',
                },
              }).then(() => {
                modalControllerChangeEdit(true)
              })
              set({
                isLoading: false,
                messageError: err?.message,
              })
            })
          set({ isLoading: false, skeletonLoading: false })
        } catch (error: any) {
          set({
            isLoading: false,
            skeletonLoading: false,
            messageError: error?.response?.data?.message,
          })
        }
      },
      removeCtvForCity: async (userId) => {
        try {
          const { listRelationShipClient } = get()
          await api.put(`/users/relationships/ctv/${userId}`)
          listRelationShipClient(String(userId))
        } catch (error: any) {
          set({
            isLoading: false,
            messageError: error?.response?.data?.message,
          })
        }
      },
      update: async (params, userId, callbackUpdateAndRemoveRelationShip) => {
        const { list, modalControllerChangeEdit, getUser } = get()
        set({ isLoading: true, messageError: '' })
        await api
          .put(`/users/${userId}`, params)
          .then((resp) => {
            set({
              isLoading: false,
              messageError: '',
              skeletonLoading: false,
            })
            getUser(userId)
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              text: resp.data.message,
              confirmButtonColor: '#2b78c0',
              confirmButtonText: 'OK',
              customClass: {
                container: 'alert-container',
              },
            }).then(() => {
              modalControllerChangeEdit(false)
            })
            list()
            callbackUpdateAndRemoveRelationShip?.(userId)
          })
          .catch((err) => {
            set({
              isLoading: false,
              messageError: err.response?.data?.errors,
            })
          })
      },
      list: async (filters) => {
        try {
          const { data: userList } = await api.get(`/users?${filters}`)
          set({
            isLoading: false,
            userList,
          })
          return userList
        } catch (error: any) {
          set({
            isLoading: false,
            messageError: error?.response?.data?.message,
          })
        }
        return []
      },
      modalControllerChangeCreate: (value) => {
        set({
          controllerModalState: value,
        })
      },
      modalControllerChangeEdit: (value) => {
        set({
          controllerModalStateEdit: value,
        })
      },
    }),
    {
      name: 'UserStore',
    },
  ),
)
