/* eslint-disable react-hooks/rules-of-hooks */
import Swal from 'sweetalert2'
import create from 'zustand'

import { api } from '~/services/api'
import {
  IUserConsultancy,
  IConsultancyWithClient,
  IConsultancy,
  IUser,
} from '~/types/consultancy-types'

type FarmState = {
  isLoading: boolean
  messageError: any
  listApproved: []
  listDisapproved: []
  listWaiting: []
  User: IUser[]
  Clients: any
  UserConsultant: IUser
  Consultacy: IConsultancy
  aba: number
  statesBrazil: []
  cities: []
  errors: boolean
  postModalController: boolean
  Farmers: any
  controllerModalStateEdit: boolean
  skeletonLoading: boolean
  usersAvailable: any
}

type RequestResponse = {
  message: string
}

type FarmActions = {
  listAprovedConsultancy: () => Promise<void>
  listDisapprovedConsultancy: () => Promise<void>
  listWaitingConsultancy: () => Promise<void>
  updateAndDeprecate: (consultancyId: number, status: number) => Promise<void>
  consultancyById: (consultancyId: number | string) => Promise<void>
  userConsultancy: (consultancyId: number | string) => Promise<void>
  clientConsultancy: (consultancyId: number | string) => Promise<void>
  setNavigation: (aba: number) => void
  clearFormsCreateUserConsultancy: () => void
  listStatesBrazil: () => Promise<void>
  modalControllerChangeCreate: (value: boolean) => void
  requestGetConsultantConsultancy: () => Promise<void>
  requestCreateConsultantConsultancy: (usuarioId?: string, consultancyId?: string) => Promise<void>
  requestGetFarmerConsultantConsultancy: () => Promise<void>
  requestCreateFarmerConsultancy: (usuarioId?: string, consultancyId?: string) => Promise<void>
  requestDeleteFarmerConsultancy: (usuarioId?: string, consultancyId?: string) => Promise<void>
  requestResendEmail: (usuarioId?: string, consultancyId?: string) => Promise<void>
  readonly modalControllerChangeEdit: (value: boolean) => void
  readonly requestUpdateConsultancy: (consultancyId?: number, params?: any) => void
  readonly clearFormsCreateUser: () => void
  userConsultancyById: (usuarioId?: string) => Promise<void>
  readonly requestUpdateUserConsultancy: (
    usuarioId?: number,
    params?: any,
    consultancyId?: string | number,
  ) => void
  requestDeleteConsultantConsultancy: (usuarioId?: string, consultancyId?: string) => void
  createConsultancy: (params: any) => Promise<void>
}

type State = FarmState & FarmActions

const initialState: FarmState = {
  isLoading: false,
  errors: false,
  postModalController: false,
  messageError: [],
  listApproved: [],
  listDisapproved: [],
  listWaiting: [],
  User: [],
  statesBrazil: [],
  cities: [],
  aba: 0,
  Clients: [],
  Consultacy: {
    bairro: '',
    celular: '',
    cep: '',
    cidade_id: '',
    created_at: new Date(),
    documento: '',
    email: '',
    fantasia: '',
    id: undefined,
    nome: '',
    numero: '',
    estado_id: '',
    pais_id: '',
    rua: '',
    situacao: '',
    status: '',
    updated_at: new Date(),
    complemento: '',
    city: null,
  },
  UserConsultant: {} as IUser,
  Farmers: null,
  controllerModalStateEdit: false,
  skeletonLoading: false,
  usersAvailable: null,
}

export const useConsultacyStore = create<State>((set, get) => ({
  ...initialState,
  listAprovedConsultancy: async () => {
    try {
      const { data } = await api.get('consultancy/1')
      set({
        listApproved: data,
      })
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Opss, Algo erro acontenceu!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }
  },
  setNavigation: (abas) => {
    set({
      aba: abas,
    })
  },
  consultancyById: async (consultancyId) => {
    set({ isLoading: true })
    try {
      const { data } = await api.get<IConsultancy>(`consultancy/consultoria/${consultancyId}`)
      set({ Consultacy: data })
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Opss, Algo erro acontenceu!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }
  },
  userConsultancy: async (consultancyId) => {
    set({ isLoading: true })
    try {
      const { data } = await api.get<IUserConsultancy>(`consultancy/usuario/${consultancyId}`)
      set({ User: data.user })
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Opss, Algo erro acontenceu!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }
  },
  clientConsultancy: async (consultancyId) => {
    set({ isLoading: true })
    try {
      const { data } = await api.get<IConsultancyWithClient>(`consultancy/cliente/${consultancyId}`)
      set({ Clients: data.clients })
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Opss, Algo erro acontenceu!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }
  },
  listDisapprovedConsultancy: async () => {
    try {
      const { data } = await api.get('consultancy/2')
      set({
        listDisapproved: data,
      })
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Opss, Algo erro acontenceu!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }
  },
  listWaitingConsultancy: async () => {
    try {
      const { data } = await api.get('consultancy/0')
      set({
        listWaiting: data,
      })
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Opss, Algo erro acontenceu!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }
  },
  updateAndDeprecate: async (consultancyId, status) => {
    set({ isLoading: true, messageError: '' })
    const { listAprovedConsultancy, listWaitingConsultancy, listDisapprovedConsultancy } = get()
    await api
      .put(`consultancy/${consultancyId}`, {
        situacao: status,
      })
      .then((resp: any) => {
        set({
          isLoading: false,
          messageError: '',
        })
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: resp.data.message,
          confirmButtonColor: '#2b78c0',
          confirmButtonText: 'OK',
          customClass: {
            container: 'alert-container',
          },
        }).then(() => {
          listAprovedConsultancy()
          listWaitingConsultancy()
          listDisapprovedConsultancy()
        })
      })
      .catch((err: any) => {
        set({
          isLoading: false,
          messageError: err.response?.data?.errors,
        })
      })
  },
  clearFormsCreateUserConsultancy: () => {
    set({
      messageError: '',
      errors: false,
      isLoading: false,
      Consultacy: {
        bairro: '',
        celular: '',
        cep: '',
        cidade_id: '',
        created_at: new Date(),
        documento: '',
        email: '',
        fantasia: '',
        id: undefined,
        nome: '',
        numero: '',
        estado_id: '',
        pais_id: '',
        rua: '',
        situacao: '',
        status: '',
        updated_at: new Date(),
        complemento: '',
        city: null,
      },
    })
  },
  requestGetConsultantConsultancy: async () => {
    set({ isLoading: true, messageError: '' })
    try {
      const { data } = await api.get(`/consultancy/consultores-disponiveis`)
      set({ usersAvailable: data })
    } catch (error: any) {
      set({ messageError: error?.response?.data?.errors })
    } finally {
      set({ isLoading: false })
    }
  },
  requestCreateConsultantConsultancy: async (usuarioId?: string, consultancyId?: string) => {
    set({ isLoading: true, messageError: '' })
    const { userConsultancy } = get()
    try {
      const { data } = await api.post<RequestResponse>(`/consultancy/vinculo-consultor`, {
        usuario_id: usuarioId,
        consultoria_id: consultancyId,
      })
      userConsultancy(Number(consultancyId))
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: data.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } catch (error: any) {
      set({ messageError: error?.response?.data?.errors })
    } finally {
      set({ isLoading: false })
    }
  },
  requestDeleteConsultantConsultancy: async (usuarioId?: string, consultancyId?: string) => {
    set({ isLoading: true, messageError: '' })
    const { userConsultancy } = get()
    try {
      const { data } = await api.delete<RequestResponse>(
        `/consultancy/consultor/${usuarioId}/${consultancyId}`,
      )
      userConsultancy(Number(consultancyId))
      Swal.fire({
        icon: 'success',
        title: 'Removido com Sucesso',
        text: data.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } catch (error: any) {
      set({ messageError: error?.response?.data?.errors })
    } finally {
      set({ isLoading: false })
    }
  },
  requestGetFarmerConsultantConsultancy: async () => {
    set({ isLoading: true, messageError: '' })
    try {
      const { data } = await api.get(`/consultancy/agricultores-disponiveis`)
      set({ Farmers: data })
    } catch (error: any) {
      set({ messageError: error?.response?.data?.errors })
    } finally {
      set({ isLoading: false })
    }
  },
  requestCreateFarmerConsultancy: async (usuarioId?: string, consultancyId?: string) => {
    set({ isLoading: true, messageError: '' })
    const { clientConsultancy } = get()
    try {
      const { data } = await api.post<RequestResponse>(`/consultancy/vinculo-agricultor`, {
        agricultor_id: usuarioId,
        consultoria_id: consultancyId,
      })
      clientConsultancy(Number(consultancyId))
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: data.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } catch (error: any) {
      set({ messageError: error?.response?.data?.errors })
    } finally {
      set({ isLoading: false })
    }
  },
  requestDeleteFarmerConsultancy: async (usuarioId?: string, consultancyId?: string) => {
    set({ isLoading: true, messageError: '' })
    const { clientConsultancy } = get()
    try {
      const { data } = await api.delete<RequestResponse>(
        `/consultancy/agricultor/${consultancyId}/${usuarioId}`,
      )
      clientConsultancy(Number(consultancyId))
      Swal.fire({
        icon: 'success',
        title: 'Removido com Sucesso',
        text: data.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } catch (error: any) {
      set({ messageError: error?.response?.data?.errors })
    } finally {
      set({ isLoading: false })
    }
  },
  requestResendEmail: async (usuarioId?: string, consultancyId?: string) => {
    set({ isLoading: true, messageError: '' })
    try {
      const { data } = await api.post<RequestResponse>(`/consultancy/reenviar-email`, {
        agricultor_id: usuarioId,
        consultoria_id: consultancyId,
      })
      set({ Farmers: data })
      Swal.fire({
        icon: 'success',
        title: 'E-mail reenviado com Sucesso',
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } catch (error: any) {
      set({ messageError: error?.response?.data?.errors })
    } finally {
      set({ isLoading: false })
    }
  },
  modalControllerChangeEdit: (value) => {
    set({
      controllerModalStateEdit: value,
    })
  },
  requestUpdateConsultancy: async (consultancyId?: number, params?: IConsultancy) => {
    const { modalControllerChangeEdit, consultancyById } = get()
    set({ isLoading: true, messageError: '' })
    await api
      .put(`consultancy/consultancy-edit/${consultancyId}`, params)
      .then((resp) => {
        set({
          isLoading: false,
          messageError: '',
          skeletonLoading: false,
        })
        consultancyById(Number(consultancyId))
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: resp.data.message,
          confirmButtonColor: '#2b78c0',
          confirmButtonText: 'OK',
          customClass: {
            container: 'alert-container',
          },
        }).then(() => {
          modalControllerChangeEdit(false)
        })
      })
      .catch((err) => {
        set({
          isLoading: false,
          messageError: err.response?.data?.errors,
          errors: true,
        })
      })
  },
  clearFormsCreateUser: () => {
    set({ messageError: '', errors: false, isLoading: false })
  },
  userConsultancyById: async (usuarioId?: string) => {
    set({ isLoading: true })
    try {
      const { data } = await api.get<IUser>(`users/${usuarioId}`)
      set({ UserConsultant: data })
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Opss, Algo erro acontenceu!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }
  },
  requestUpdateUserConsultancy: async (usuarioId?: number, params?: IConsultancy) => {
    const { modalControllerChangeEdit, userConsultancy, Consultacy } = get()
    set({ isLoading: true, messageError: '' })
    await api
      .put(`users/${usuarioId}`, params)
      .then((resp) => {
        set({
          isLoading: false,
          messageError: '',
          skeletonLoading: false,
        })
        userConsultancy(Number(Consultacy.id))
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: resp.data.message,
          confirmButtonColor: '#2b78c0',
          confirmButtonText: 'OK',
          customClass: {
            container: 'alert-container',
          },
        }).then(() => {
          modalControllerChangeEdit(false)
        })
      })
      .catch((err) => {
        set({
          isLoading: false,
          messageError: err.response?.data?.errors,
          errors: true,
        })
      })
  },
  listStatesBrazil: async () => {
    try {
      const { data } = await api.get('/limit/estados')
      set({
        statesBrazil: data,
      })
    } catch (error: any) {
      set({
        messageError: error?.response?.data?.message,
      })
    }
  },
  createConsultancy: async (params: any) => {
    const {
      clearFormsCreateUserConsultancy,
      listAprovedConsultancy,
      listWaitingConsultancy,
      listDisapprovedConsultancy,
      modalControllerChangeCreate,
    } = get()
    set({ isLoading: true, messageError: '' })
    await api
      .post('/consultancy', params)
      .then((resp) => {
        set({
          isLoading: false,
          messageError: '',
        })
        Swal.fire({
          icon: 'success',
          title: 'Cadastrado com sucesso!',
          text: resp.data.message,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        }).then(() => {
          modalControllerChangeCreate(false)
        })
        listAprovedConsultancy()
        listWaitingConsultancy()
        listDisapprovedConsultancy()
        clearFormsCreateUserConsultancy()
        modalControllerChangeCreate(false)
      })
      .catch((error) => {
        modalControllerChangeCreate(true)
        Swal.fire({
          icon: 'error',
          title: 'Oops Algo aconteceu de errado!',
          text: error?.response?.data?.message,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        })
      })
  },
  modalControllerChangeCreate: (value) => {
    set({
      postModalController: value,
    })
  },
}))
