import { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Grid,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  Button,
  List,
  FormHelperText,
} from '@material-ui/core'
import { Done } from '@material-ui/icons'
import { AxiosError } from 'axios'
import * as yup from 'yup'

import { useAlert } from '~/hooks/message'
import { api } from '~/services/api'
import { useAuditListStore } from '~/store/audit/list-audits'
import { useStoreMissions } from '~/store/missions-store'

import { useStyles } from './styles'

type PropsState = {
  cultura_id: number
  safra_id: number
  tipo_safra: string
  missao_id: number
}

type FormState = {
  missao_id?: string
  status: string
  status_auditoria?: string
  status_pagamento?: string
  inconsistencia?: string
  tipo_safra: string
}

type ParamsRoute = {
  cliente_id: string
}

const schema = yup.object().shape({
  missao_id: yup.number().required('Campo obrigatório'),
  status: yup.number().required('Campo obrigatório'),
  status_auditoria: yup.number().required('Campo obrigatório'),
  status_pagamento: yup.number().required('Campo obrigatório'),
  inconsistencia: yup.string().required('Campo obrigatório'),
  tipo_safra: yup.string().required('Campo obrigatório'),
})

const FormAudit = () => {
  const classes = useStyles()
  const { load } = useAuditListStore()
  const { cultura_id, missao_id, safra_id, tipo_safra } = useHistory<PropsState>().location.state
  const { cliente_id } = useParams<ParamsRoute>()
  const { missions } = useStoreMissions()
  const missionSelected = missions.find((value) => {
    return (
      value.mission_detail[0]?.cultura_id === cultura_id &&
      value.mission_detail[0]?.safra_id === safra_id &&
      value.mission_detail[0]?.missao_id === missao_id
    )
  })
  const alert = useAlert()

  const { handleSubmit, control, formState, reset, setValue } = useForm<FormState>({
    resolver: yupResolver(schema),
  })
  const { errors } = formState

  useEffect(() => {
    api
      .get('/audit/misson/payment', {
        params: { cliente_id, cultura_id, safra_id, tipo_safra },
      })
      .then((res: any) => {
        reset({ ...res.data, missao_id: missionSelected?.id })
      })
      .catch((error: AxiosError) => {
        alert.show({ message: error.response?.data.message, type: 'error' })
      })
  }, [])

  const onSubmit = (data: FormState) => {
    api
      .put(`/audit/${cliente_id}`, {
        ...data,
        cultura_id,
        safra_id,
        cliente_id,
      })
      .then(() => {
        load()
        alert.show({ message: 'Atualizado com sucesso', type: 'success' })
      })
      .catch((error: AxiosError) =>
        alert.show({ message: error.response?.data.message, type: 'error' }),
      )
  }

  return (
    <>
      <List className={classes.listPage}>
        <Grid container item xs={12}>
          <Grid item xs={12} className={classes.textLeft}>
            <Typography variant="h6" className={classes.titleFarmer}>
              Formulário de Auditoria
            </Typography>
          </Grid>
          <Grid item xs={12} container className={classes.textLeft} spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="missao_id"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Grid item xs={12}>
                    <InputLabel id="missao_id" className={classes.textInput}>
                      Missão
                    </InputLabel>
                    <Select
                      labelId="missao_id"
                      id="missao_id"
                      {...field}
                      className={classes.selectInput}
                      required
                      disabled
                    >
                      <MenuItem value={missionSelected?.id}>{missionSelected?.titulo}</MenuItem>
                    </Select>
                  </Grid>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="tipo_safra"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Grid item xs={12}>
                    <InputLabel id="tipo_safra" className={classes.textInput}>
                      Tipo de Safra
                    </InputLabel>
                    <Select
                      labelId="tipo_safra"
                      id="tipo_safra"
                      className={classes.selectInput}
                      required
                      disabled
                      {...field}
                    >
                      <MenuItem value={1}>1º Safra</MenuItem>
                      <MenuItem value={2}>2º Safra</MenuItem>
                    </Select>
                    {errors.tipo_safra?.message && (
                      <FormHelperText className={classes.helperText}>
                        {errors.tipo_safra?.message}
                      </FormHelperText>
                    )}
                  </Grid>
                )}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container className={classes.textLeft} spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="status"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Grid item xs={12}>
                    <InputLabel id="status" className={classes.textInput}>
                      Status
                    </InputLabel>
                    <Select
                      labelId="status"
                      id="status"
                      className={classes.selectInput}
                      required
                      {...field}
                    >
                      <MenuItem value="0">Inativo</MenuItem>
                      <MenuItem value="1">Ativo</MenuItem>
                    </Select>
                  </Grid>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="status_auditoria"
                control={control}
                defaultValue="1"
                render={({ field: { onChange, ...fields } }) => (
                  <Grid item xs={12}>
                    <InputLabel id="status_auditoria" className={classes.textInput}>
                      Situação
                    </InputLabel>
                    <Select
                      labelId="status_auditoria"
                      id="status_auditoria"
                      onChange={(e) => {
                        if (e.target.value === '3') setValue('status_pagamento', '2')
                        onChange(e.target.value)
                      }}
                      className={classes.selectInput}
                      required
                      {...fields}
                    >
                      <MenuItem value="1" disabled>
                        Validação em andamento
                      </MenuItem>
                      <MenuItem value="2">Auditoria Em andamento</MenuItem>
                      <MenuItem value="3">Aprovado</MenuItem>
                      <MenuItem value="4">Reprovado</MenuItem>
                    </Select>
                  </Grid>
                )}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container className={classes.textLeft} spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="inconsistencia"
                control={control}
                defaultValue="0"
                render={({ field }) => (
                  <Grid item xs={12}>
                    <InputLabel id="inconsistencia" className={classes.textInput}>
                      Inconsistência Encontrada
                    </InputLabel>
                    <Select
                      labelId="inconsistencia"
                      id="inconsistencia"
                      className={classes.selectInput}
                      required
                      {...field}
                    >
                      <MenuItem value="0">Nenhuma inconsistência</MenuItem>
                      <MenuItem value="1">Lançamento não autorizado pelo agricultor</MenuItem>
                    </Select>
                  </Grid>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="status_pagamento"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Grid item xs={12}>
                    <InputLabel id="status_pagamento" className={classes.textInput}>
                      Status Pagamento
                    </InputLabel>
                    <Select
                      labelId="status_pagamento"
                      id="status_pagamento"
                      {...field}
                      className={classes.selectInput}
                      required
                    >
                      <MenuItem value={1}>Aguardando liberação</MenuItem>
                      <MenuItem value={2}>Pagamento Liberado</MenuItem>
                      <MenuItem value={5}>Pago</MenuItem>
                      <MenuItem value={6}>Pagamento não liberado</MenuItem>
                    </Select>
                  </Grid>
                )}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.gridButtonSubmit}>
            <Button
              id="button-salvar-form-auditoria"
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<Done />}
              onClick={handleSubmit(onSubmit)}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </List>
    </>
  )
}

export default FormAudit
