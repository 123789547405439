import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  gridMain: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '24px 24px 24px 24px',
    flexGrow: 1,
    minHeight: 696,
    height: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    flex: 1,
    height: '100%',
    width: '100%',
    textAlign: 'center',
    flexDirection: 'column',
    color: theme.palette.text.secondary,
    borderRadius: '16px',
    '@media (max-width: 1366px)': {
      minHeight: 690,
    },
    '@global': {
      '*::-webkit-scrollbar': {
        width: '2px',
        height: '4.5px',
      },
      '*::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 5px grey',
        borderRadius: '10px',
      },
      '*::-webkit-scrollbar-thumb': {
        background: theme.palette.primary.main,
        borderRadius: '10px',
      },
      '*::-webkit-scrollbar-thumb:hover': {
        background: theme.palette.primary.dark,
      },
    },
  },
  iconBack: {
    minWidth: '60px',
    minHeight: '60px',
    borderRadius: 90,
  },
  headerContainer: {
    marginTop: 24,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
  },
  selectInput: {
    width: '100%',
  },
  helperText: {
    color: theme.palette.error.main,
  },
  buttonFilter: {
    backgroundColor: '#48bbe6',
    color: '#fff',
    fontSize: '14px',
    width: '100px',
    height: '50px',
  },
  titleName: {
    color: '#21a4d3',
    marginLeft: 32,
    textTransform: 'uppercase',
    textAlign: 'left',
  },
  inputFilter: {
    marginRight: 40,
  },
  bodyInformation: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
    alignContent: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: '24px 0px',
    padding: '8px 0px',
  },
  bodyRefs: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  validationContent: {
    padding: '0px 10px',
  },
  primary: {
    color: '#24D2B5',
    fontSize: '25px',
    fontWeight: 500,
  },
  secundary: {
    color: '#989b9a',
    fontSize: '22px',
    fontWeight: 200,
  },
  insert: {
    color: '#455A64',
    fontWeight: 500,
    fontSize: 18,
    padding: 10,
  },
  validation: {
    color: '#2679CF',
    fontWeight: 500,
    fontSize: 18,
    padding: 10,
  },
  aproved: {
    color: '#00820E',
    fontWeight: 500,
    fontSize: 18,
    padding: 10,
  },
  disapproved: {
    color: '#A70000',
    fontWeight: 500,
    fontSize: 18,
    padding: 10,
  },
}))
