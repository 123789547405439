export const PerfilAdminNum = {
  0: 'Selecione um opção',
  1: 'TI',
  2: 'Admin',
  3: 'Diretoria',
  4: 'CTV',
}

export const dataPais = [
  {
    pais: 'Brasil',
    id_pais: '1',
    codigo_area: '+55',
  },
  {
    pais: 'Paraguai',
    id_pais: '168',
    codigo_area: '+595',
  },
]

export const Status = [
  {
    statusName: 'Inativo',
    statusValue: '0',
  },
  {
    statusName: 'Ativo',
    statusValue: '1',
  },
]
export const Visualizacao = [
  {
    statusName: 'Dados detalhados',
    statusValue: '1',
  },
  {
    statusName: 'Dados consolidados',
    statusValue: '2',
  },
]
export const Cargo = [
  {
    nameValue: 'Proprietário',
    value: 10,
  },
  {
    nameValue: 'Supervisor',
    value: 11,
  },
  {
    nameValue: 'Consultor',
    value: 12,
  },
  {
    nameValue: 'Cargo Administrativo',
    value: 13,
  },
  {
    nameValue: 'Gerente',
    value: 14,
  },
  {
    nameValue: 'Coordenador',
    value: 15,
  },
  {
    nameValue: 'Proprietário/Socio (a)',
    value: 1,
  },
  {
    nameValue: 'Filho (a)',
    value: 2,
  },
  {
    nameValue: 'Supervisor (a)',
    value: 6,
  },
  {
    nameValue: 'Cargo administrativo (a)',
    value: 7,
  },
  {
    nameValue: 'Agrônomo (a) da Propriedade',
    value: 8,
  },
  {
    nameValue: 'Consultor Técnico (a) - Externo',
    value: 9,
  },
  {
    nameValue: 'Diretor (a)',
    value: 3,
  },
  {
    nameValue: 'Gerente',
    value: 4,
  },
  {
    nameValue: 'Coordenador (a) (Agricultor)',
    value: 5,
  },
]
export const CargoConsultoria = [
  {
    nameValue: 'Proprietário',
    value: 10,
  },
  {
    nameValue: 'Supervisor',
    value: 11,
  },
  {
    nameValue: 'Consultor',
    value: 12,
  },
  {
    nameValue: 'Cargo Administrativo',
    value: 13,
  },
  {
    nameValue: 'Gerente',
    value: 14,
  },
  {
    nameValue: 'Coordenador',
    value: 15,
  },
]
export const PerfilAdmin = [
  {
    nameValue: 'Selecione uma opção',
    value: '0',
  },
  {
    nameValue: 'TI',
    value: '1',
  },
  {
    nameValue: 'Admin',
    value: '2',
  },
  {
    nameValue: 'Diretoria',
    value: '3',
  },
  {
    nameValue: 'CTV',
    value: '4',
  },
]

export const PerfilId = [
  {
    nameValue: 'Administrador',
    value: '1',
  },
  {
    nameValue: 'Consultor',
    value: '2',
  },
]
export const agpro = [
  {
    nameValue: 'Sim',
    value: '1',
  },
  {
    nameValue: 'Não',
    value: '0',
  },
]

export const associationMemberField = [
  {
    nameValue: 'Sim',
    value: '1',
  },
  {
    nameValue: 'Não',
    value: '0',
  },
] as const

export const [associationMemberYesOption, associationMemberNoOption] = associationMemberField

export const associationAdminField = [
  {
    nameValue: 'Sim',
    value: '1',
  },
  {
    nameValue: 'Não',
    value: '2',
  },
] as const

export const [associationAdminYesOption, associationAdminNoOption] = associationAdminField

export const DefaultInput = {
  paisDefault: '1',
  statusDefault: '1',
  perfilAdminDefault: '0',
  agProDefault: '0',
  associationMemberDefault: associationMemberNoOption.value,
  associationAdminDefault: associationAdminNoOption.value,
  CargoDefault: '5',
  SituationConsultantDefault: '1',
}

export const DefaultInputRegion = {
  cultura_id: 2,
}

export const cuture = [
  {
    id: 2,
    nome: 'Algodão',
    status: 'ativo',

    cultureName: 'Soja',
    cutureValue: 1,
  },
  {
    cultureName: 'Milho',
    cutureValue: 3,
  },
]

export const cutureRegion = [
  {
    cultureName: 'Algodão',
    cutureValue: 2,
  },
]

export const fetaures = [
  {
    fetauresName: 'Moderadamente Resistente',
    fetauresValue: 'Moderadamente Resistente',
  },
  {
    fetauresName: 'Resistente',
    fetauresValue: 'Resistente',
  },
  {
    fetauresName: 'Moderadamente Suscetível',
    fetauresValue: 'Moderadamente Suscetível',
  },
  {
    fetauresName: 'Suscetível',
    fetauresValue: 'Suscetível',
  },
  {
    fetauresName: 'Sem Informação',
    fetauresValue: 'Sem Informação',
  },
]

export const tipo = [
  {
    situacao: 0,
    situacaoValue: 'Tudo',
  },
  {
    situacao: 1,
    situacaoValue: 'Pendente',
  },
]

export const allResults = [
  {
    descricao: 'Tudo',
    cultura_id: '0',
    safra_id: '0',
    tipo_safra: '0',
    area_pendente: '0',
  },
]

export const farmer = [
  {
    value: '1',
    label: 'Sim',
  },
  {
    value: '2',
    label: 'Não',
  },
]

export const interacoes = [
  {
    value: 1,
    label: 'E-mail',
  },
  {
    value: 2,
    label: 'Ligação',
  },
  {
    value: 3,
    label: 'WhatsApp',
  },
]

export const motivos = [
  {
    value: 1,
    label: 'Aguardando Correção',
  },
  {
    value: 2,
    label: 'Aguardando Referências',
  },
  {
    value: 3,
    label: 'Área Incompleta',
  },
  {
    value: 4,
    label: 'Checando Referências',
  },
  {
    value: 5,
    label: 'Entar em Contato',
  },
  {
    value: 6,
    label: 'Lançamento Não Autorizado',
  },
  {
    value: 7,
    label: 'Não é Agricultor',
  },
  {
    value: 8,
    label: 'Não tem Interesse',
  },
  {
    value: 9,
    label: 'Tentativa De Contato',
  },
  {
    value: 10,
    label: 'Validação Ok',
  },
]

export const resultados = [
  {
    value: 1,
    label: 'Aprovado',
  },
  {
    value: 2,
    label: 'Em Andamento',
  },
  {
    value: 3,
    label: 'Incompleto',
  },
  {
    value: 4,
    label: 'Novo',
  },
  {
    value: 5,
    label: 'Pendente',
  },
  {
    value: 6,
    label: 'Reprovado',
  },
]

export const funcoes = [
  {
    value: 1,
    label: 'Referência Externa',
  },
]
export const fonte = [
  {
    value: 1,
    label: 'AGPro',
  },
  {
    value: 2,
    label: 'Consultor',
  },
  {
    value: 3,
    label: 'CTV',
  },
  {
    value: 4,
    label: 'Diretor',
  },
  {
    value: 5,
    label: 'Gerente',
  },
  {
    value: 6,
    label: 'RCA',
  },
]

export const incompletes = [
  {
    value: '1',
    label: 'Sapiens',
  },
  {
    value: '2',
    label: 'Protege',
  },
  {
    value: '3',
    label: 'Brain',
  },
  {
    value: '4',
    label: 'Equipe ATTO',
  },
  {
    value: '5',
    label: 'Referência do Cliente',
  },
  {
    value: '6',
    label: 'Cliente',
  },
  {
    value: '7',
    label: 'Outro',
  },
]

export const disapproved = [
  {
    value: '1',
    label: 'Não é agricultor',
  },
  {
    value: '2',
    label: 'Não Conseguiu Contato',
  },
]

export const citiesDefault = [{ id: '0', descricao: 'Selecione um estado' }]
export const filterSetMount = {
  situacao: '0',
  cultura: [
    {
      descricao: 'Tudo',
      cultura_id: '0',
      safra_id: '0',
      tipo_safra: '0',
      area_pendente: '0',
    },
  ],
}

export const SituationConsultant = [
  { value: '0', description: 'Aguardando Aprovação' },
  { value: '1', description: 'Aprovada' },
  { value: '2', description: 'Reprovada' },
]
