import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: any) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%', // Fix IE 11 issue
  },
  resumeTable: {
    flex: 1,
    display: 'flex',
    width: 'auto',
  },
}))
