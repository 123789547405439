import { ReactElement, useEffect, useState } from 'react'

import { Grid, Paper, Typography } from '@material-ui/core'
import { DataGrid, GridColDef, GridValueGetterParams } from '@material-ui/data-grid'

import { GRID_DEFAULT_LOCALE_TEXT } from '~/constants/translate-data-grid'
import { TypeCargos } from '~/enums/enum'
import { useClientStore } from '~/store/client/client-store'
import { FormatPhoneWithCountry } from '~/utils'

import { useStyles } from './styles'

type Props = {
  cliente_id: string
  title: string
}

function AgproClient(props: Props): ReactElement {
  const classes = useStyles()
  const { cliente_id, title } = props
  const [pageSize, setPageSize] = useState(5)
  const { getFarmerLinkedAgpros, farmerLinkedAgpros } = useClientStore()
  useEffect(() => {
    async function getRequets() {
      await getFarmerLinkedAgpros(cliente_id)
    }
    getRequets()
  }, [])

  const columns: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'Nome',
      width: 150,
      flex: 0.3,
    },
    {
      field: 'role',
      headerName: 'Cargo',
      width: 150,
      flex: 0.2,
      valueGetter: (params: GridValueGetterParams) => {
        return params?.row?.cargo_id
      },
      renderCell: (params) => TypeCargos[params?.row?.cargo_id],
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 150,
      flex: 0.3,
    },
    {
      field: 'celular',
      headerName: 'Celular',
      width: 160,
      valueGetter: (params: GridValueGetterParams) =>
        params.row?.celular
          ? FormatPhoneWithCountry(params.row?.pais_celular, params.row?.celular)
          : '-',
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return <Typography variant="body1">{params.value}</Typography>
      },
      valueGetter: (params: GridValueGetterParams) => {
        return params.value === '1' ? 'Ativo' : 'Inativo'
      },
    },
  ]

  return (
    <Paper variant="outlined" className={classes.paper}>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          <Grid container item xs={12}>
            <Typography variant="h5" className={classes.titleStyle}>
              {title}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} className={classes.resumeTable}>
          <DataGrid
            pageSize={pageSize}
            onPageSizeChange={(newPageSize: any) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 25]}
            rows={farmerLinkedAgpros}
            columns={columns}
            localeText={GRID_DEFAULT_LOCALE_TEXT}
            autoHeight
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default AgproClient
