import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  gridMain: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '24px 24px 24px 24px',
    flexGrow: 1,
    minHeight: 696,
    height: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    flex: 1,
    height: '100%',
    width: '100%',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderRadius: '16px',
    minHeight: 690,
  },
  username: {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
  subtitle: {
    marginTop: theme.spacing(5),
    color: theme.palette.text.primary,
  },
  gridSubtitle: {
    display: 'flex',
    justifyContent: 'center',
  },
  textPlantup: {
    color: '#f8bc00',
    fontWeight: 'bold',
  },
  gridFastAccess: {
    textAlign: 'left',
    color: '#f8bc00',
    marginTop: '48px',
    marginLeft: '16px',
  },
  gridCardPresentation: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    margin: 0,
    marginTop: '24px',
    gap: '24px',
    justifyContent: 'center',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    flexWrap: 'wrap',
  },

  iconsFastAccess: {
    fontSize: 100,
    backgroundColor: '#303f9f',
    borderRadius: 90,
    padding: 20,
    color: 'white',
  },
  cardPaper: {
    display: 'flex',
    justifyContent: 'space-evenly',
    height: 300,
    borderRadius: 30,
  },

  gridCopyright: {
    height: 16,
  },
}))
