import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: any) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%', // Fix IE 11 issue
  },
  root: {
    '& label + .MuiInput-formControl': {
      marginTop: '1vw',
    },
  },
  resumeTable: {
    flex: 1,
    display: 'flex',
    width: 'auto',
  },
  titleStyle: {
    textAlign: 'left',
    marginLeft: '16px',
  },
  buttonDelete: {
    color: 'white',
    '&:hover': {
      backgroundColor: 'red',
      filter: `brightness('0.9')`,
    },
  },
  textInput: {
    minWidth: '300px',
    fontSize: '12px',
    color: '#00446B',
    borderRadius: '30px',
    paddingRight: '5px',
  },
  helperText: {
    color: theme.palette.error.main,
  },
  primary: {
    color: '#002f4a',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  secundary: {
    color: '#989b9a',
    fontSize: '14px',
  },
}))
